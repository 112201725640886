import React, { useState } from 'react';
import ProgressBar from 'components/Global/ProgressBar';
import DetailPenyewa from './DetailPenyewa';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBanks } from 'features/banks/actions';
import DetailSewa from './DetailSewa';
import { getAllDeliveryLocations } from 'features/delivery-location/actions';
import {
  getAllVehicleAirportPackage,
  getVehicleByIdAirportPackage,
  getVehiclesByFilter,
} from 'features/vehicle/action';
import KonfirmasiPesanan from './KonfirmasiPesanan';
import { TableWrapper } from 'components/Global';
import { ReactComponent as VehicleLogoIcon } from '../../../../icons/custom-order-filled-icon.svg';
import MetodePembayaran from './MetodePembayaran';
import { getPaymentMethods } from 'features/payment/actions';
import { resetViolations, setViolations } from 'features/violations/violationsSlice';
import { useLocation } from 'react-router-dom';
import { getAllCouriers } from 'features/couriers/actions';
import { resetDataVehicle } from 'features/vehicle/vehicleSlice';
import { convertBase64toImage } from 'utils/functionality';
import { setSummaryData } from 'features/orders/summaryOrderSlice';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import { getRentalLocationByServices } from 'features/rental-location/actions';
import { resetSummaryData } from 'features/orders/summaryOrderSlice';
import { getShuttleAirportZone, getShuttleTheAirport } from 'features/shuttle-airport/actions';
import { ReactComponent as AirportLogoIcon } from 'icons/airport-transfer-filled-icon.svg';
import { ReactComponent as OneWayLogoIcon } from 'icons/one-way-filled-icon.svg';
import { getServices } from 'features/services/actions';
import { getAllAddonByParams } from 'features/addon/actions';
import { getAllOneWayPackages } from 'features/one-way-packages/actions';

// PROGRESS BAR ARRAY
const progressBarPage = [
  {
    id: 'pbp1',
    title: 'Detail Penyewa',
  },
  {
    id: 'pbp2',
    title: 'Detail Sewa',
  },
  {
    id: 'pbp3',
    title: 'Detail Pembayaran',
  },
  {
    id: 'pbp4',
    title: 'Konfirmasi Pemesanan',
  },
];

// INITIAL STATE ORDER
const initialState = {
  order_type_id: 1,
  user_name: '',
  phone_number: '',
  phone_country_code: '+62',
  wa_number: '',
  email: '',
  booking_price: 0,
  type: '',
  service_fee: 0,
  rental_delivery_fee: 0,
  rental_return_fee: 0,
  insurance_fee: 0,
  total_payment: 0,
  deposit: 0,
  deposit_e_toll: 0,
  over_time_price: 0,
  over_time: 0,
  order_violations: [],
  currency: '',
  order_detail: {
    package_id: '',
    baggage: '',
    vehicle_id: '',
    is_take_from_rental_office: false,
    rental_delivery_location: '',
    rental_delivery_location_detail: '',
    rental_return_location: '',
    rental_return_location_detail: '',
    end_booking_date: '',
    end_booking_time: '',
    start_booking_date: '',
    start_booking_time: '',
    landing_time: '',
    flight_number: '',
    additionals: [],
    identity: null,
    without_driver: 1,
    passenger_number: 0,
    airport_name: '',
  },
  disbursement: {
    payment_method_id: 0,
    sender_name: '',
    sender_bank_name: '',
    sender_bank_number: 0,
    disbursement_confirmation_image: '',
    time_zone: '',
  },
  remainder: 0,
  price_per_day: 0,
  exceed_passenger_price: 0,
  down_payment: 0,
};

const CustomOrderComponent = () => {
  const dispatch = useDispatch();
  const pathLocation = useLocation();

  const pathName = pathLocation.pathname.split('/').filter((item) => item !== '')[0];

  // GLOBAL STATE
  const { data: dataVehicle } = useSelector((state) => state.vehicle);
  const { zoneList } = useSelector((state) => state.zone);
  const { data: rentalServicesData } = useSelector((state) => state.services);

  // LOCAL STATE
  const [orderData, setOrderData] = useState(initialState);
  const [page, setPage] = useState(1);
  // page(1)
  const [depositBank, setDepositBank] = useState('');
  const [depositAccountNumber, setDepositAccountNumber] = useState('');
  // page(2)
  const [refferalCode, setRefferalCode] = useState('');
  const [driver, setDriver] = useState(0);
  const [rentalLocationId, setRentalLocationId] = useState('');

  // TEMPORARY LINK URL IMAGE KTP (page1)
  const [ktpImageLink, setKtpImageLink] = useState('');
  // TEMPORARY LINK URL IMAGE SIM (page1)
  const [simImageLink, setSimImageLink] = useState('');

  // ALL VEHICLES
  const [allVehicle, setAllVehicle] = useState([]);
  // TEMPORARY VEHICLE INPUT STATE (page2)
  const [vehicleInput, setVehicleInput] = useState(orderData.order_detail.vehicle_id);
  // TEMPORARY VEHICLE STATE (page2)
  const [selectedCar, setSelectedCar] = useState(null);
  // TEMPORARY START RENT DATE (page2)
  const [startRentDate, setStartRentDate] = useState('');
  // TEMPORARY END RENT DATE (page2)
  const [endRentDate, setEndRentDate] = useState('');
  // TEMPORARY DELIVERY LOCATION (page2)
  const [deliveryLocation, setDeliveryLocation] = useState({});
  // TEMPORARY RETURN LOCATION (page2)
  const [returnLocation, setReturnLocation] = useState({});
  // TEMPORARY OVERTIME
  const [selectedOvertime, setSelectedOvertime] = useState('');
  // TEMPORARY PAYMENT TYPE
  const [selectedPaymentType, setSelectedPaymentType] = useState('');

  // TEMPORARY LINK URL PAYMENT IMAGE (page3)
  const [paymentImageLink, setPaymentImageLink] = useState('');

  // TEMPORARY DURATION FOR WITH DRIVER
  const [durationRent, setDurationRent] = useState(0);

  // ALL ZONE
  const [allZonesList, setAllZonesList] = useState([]);

  // DATA TO ENTER TO LOCAL STORAGE
  const [orderDataLocalStorage, setOrderDataLocalStorage] = useState({
    order_data: undefined,
    summary_order_data: {},
    ktpImageLink: '',
    simImageLink: '',
    paymentImageLink: '',
    deliveryLocation: {},
    returnLocation: {},
    startRentDate: '',
    endRentDate: '',
    selectedOvertime: '',
    selectedPaymentType: '',
    selectedCar: null,
    driver: 0,
    duration: 0,
    page,
  });

  // LOADING STATE
  const [isLoading, setIsLoading] = useState(true);
  // DURATION PER DAY WITH DRIVER
  const [dayDuration, setDayDuration] = useState([]);
  // SWITCH SHUTTLE AIRPORT
  const [isSwitch, setIsSwitch] = useState(false);

  // TEMPORARY START LOCATION (page2)
  const [startLocation, setStartLocation] = useState({
    name: '',
    lat: 0,
    lng: 0,
  });
  // TEMPORARY END LOCATION (page2)
  const [endLocation, setEndLocation] = useState({
    name: '',
    lat: 0,
    lng: 0,
  });
  const [locationData, setLocationData] = useState({ latlng: [-8.2271303, 115.1919203], bounds: null });

  // SHUTTLE AIRPORT
  // const [shuttleData, setShuttleData] = useState([]);

  // ADDITIONAL ITEM
  const [selectedAdditionalItem, setSelectedAdditionalItem] = useState(undefined);

  // DESCRIPTION ADDITIONAL
  // const [descriptionAdditionalItem, setDescriptionAdditionalItem] = useState('');

  // GO TO NEXT PAGE FUNCTION
  const nextPage = () => {
    if (page === progressBarPage.length) return;
    setPage((prevState) => prevState + 1);
  };

  // GO TO PREV PAGE FUNCTION
  const prevPage = () => {
    if (page === 1) return;
    setPage((prevState) => prevState - 1);
  };

  // GET ALL REQUIRED DATA
  useEffect(() => {
    dispatch(resetSummaryData());
    dispatch(getAllBanks());
    dispatch(getPaymentMethods());
    dispatch(getServices());

    if (pathName === 'without-driver' || pathName === 'with-driver') {
      dispatch(resetViolations());
      dispatch(getAllCouriers());
      dispatch(resetDataVehicle());
    }
  }, []);

  // GET RENTAL LOCATION BY SERVICES BASE ON TYPE ORDER
  useEffect(() => {
    if (!rentalServicesData.length) return;
    const convertingPathName = pathName
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    const serviceSewaMobil = rentalServicesData.find((item) => item.name === 'Sewa Mobil');

    const service_id = serviceSewaMobil?.id;
    let sub_service_id;
    let facility_id;

    if (convertingPathName === 'Airport Transfer' || convertingPathName === 'One Way') {
      sub_service_id = rentalServicesData
        ?.find((item) => item.id === service_id)
        ?.sub_services?.find((item) => item.name === convertingPathName)?.id;
      facility_id = undefined;
    } else if (convertingPathName === 'With Driver' || convertingPathName === 'Without Driver') {
      sub_service_id = rentalServicesData
        ?.find((item) => item.id === service_id)
        ?.sub_services?.find((item) => item.name === 'Daily')?.id;

      facility_id = rentalServicesData
        ?.find((item) => item.id === service_id)
        ?.sub_services?.find((item) => item.name === 'Daily')
        ?.facilities?.find((item) => item.name === convertingPathName)?.id;
    }

    dispatch(getRentalLocationByServices({ service_id, sub_service_id, facility_id }));
  }, [rentalServicesData]);

  // GET DELIVERY LOCATION WHEN LOCATION HAS BEEN CHOOSE
  useEffect(() => {
    if (!rentalLocationId) return;

    if (pathName === 'airport-transfer') {
      dispatch(getShuttleAirportZone({ locationId: rentalLocationId.id, limit: 100 }));
      dispatch(getShuttleTheAirport({ locationId: rentalLocationId.id, limit: 100 }));
    }

    if (pathName === 'without-driver') {
      dispatch(getAllDeliveryLocations({ id: rentalLocationId.id, limit: 30 }));
    }
  }, [rentalLocationId]);

  // GET ALL VEHICLES BY FILTER LOCATION START AND END RENT
  useEffect(() => {
    if (
      !rentalLocationId ||
      !orderData.order_detail.start_booking_date ||
      !orderData.order_detail.end_booking_date ||
      !orderData.order_detail.start_booking_time ||
      !orderData.order_detail.end_booking_time
    )
      return;

    const convertingPathName = pathName
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    const serviceSewaMobil = rentalServicesData.find((item) => item.name === 'Sewa Mobil');
    const service_id = serviceSewaMobil?.id;

    const sub_service_id = rentalServicesData
      ?.find((item) => item.id === service_id)
      ?.sub_services?.find((item) => item.name === 'Daily')
      ?.facilities?.find((item) => item.name === convertingPathName)?.id;

    const payload = {
      locationRental: rentalLocationId.id,
      startTrip: `${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`,
      endTrip: `${orderData.order_detail.end_booking_date} ${orderData.order_detail.end_booking_time}`,
      supportDriver: !orderData.order_detail.without_driver,
      customOrder: true,
      page: 0,
      limit: 0,
      subServiceId: sub_service_id,
    };
    dispatch(getVehiclesByFilter(payload));
  }, [
    orderData.order_detail.start_booking_date,
    orderData.order_detail.end_booking_date,
    orderData.order_detail.start_booking_time,
    orderData.order_detail.end_booking_time,
    orderData.order_detail.without_driver,
    rentalLocationId,
  ]);

  // SET ALL VEHICLE WHEN DATA VEHICLE CHANGING
  useEffect(() => {
    if (Object.keys(dataVehicle).length === 0) return;
    if (dataVehicle.vehicles === null) {
      setAllVehicle([]);
      return;
    }
    if (dataVehicle?.pagination.page > 1) {
      setAllVehicle((prevState) => [...prevState, ...dataVehicle.vehicles]);
    } else {
      setAllVehicle(dataVehicle.vehicles);
    }
  }, [dataVehicle]);

  // PHONE NUMBER
  useEffect(() => {
    if (orderData.wa_number === '') return;
    setOrderData({ ...orderData, phone_number: orderData.wa_number });
  }, [orderData.wa_number]);

  // SET TYPE OF ORDER BASE ON A PATH &
  // LOCAL STORAGE DATA
  useEffect(() => {
    const storageOrderData = localStorage.getItem('order-data');
    const typeOrder = pathName;

    const convertFileBase64 = async (fileBase64, callback) => {
      if (fileBase64 === '') {
        callback(fileBase64);
      } else {
        const file = await convertBase64toImage(fileBase64, 'identity-image');
        callback(file);
      }
    };

    if (storageOrderData) {
      const newOrderData = JSON.parse(storageOrderData);
      setOrderDataLocalStorage(newOrderData);
      setDeliveryLocation(newOrderData.deliveryLocation);
      setReturnLocation(newOrderData.returnLocation);
      setStartRentDate(!newOrderData.startRentDate ? newOrderData.startRentDate : new Date(newOrderData.startRentDate));
      setEndRentDate(!newOrderData.endRentDate ? newOrderData.endRentDate : new Date(newOrderData.endRentDate));
      setSelectedOvertime(newOrderData.selectedOvertime);
      setSelectedPaymentType(newOrderData.selectedPaymentType);
      setSelectedCar(newOrderData.selectedCar);
      setDriver(newOrderData.driver);
      dispatch(setViolations(newOrderData.order_data?.order_violations));
      convertFileBase64(newOrderData.ktpImageLink, setKtpImageLink);
      convertFileBase64(newOrderData.simImageLink, setSimImageLink);
      convertFileBase64(newOrderData.paymentImageLink, setPaymentImageLink);
      setDurationRent(newOrderData.duration);
      setPage(newOrderData.page);
      dispatch(setSummaryData(newOrderData.summary_order_data));

      if (typeOrder === 'without-driver') {
        setOrderData({
          ...newOrderData.order_data,
          order_detail: { ...newOrderData.order_data.order_detail, without_driver: true },
        });
      } else {
        setOrderData({
          ...newOrderData.order_data,
          order_detail: { ...newOrderData.order_data.order_detail, without_driver: false },
        });
      }
    } else {
      if (typeOrder === 'without-driver') {
        setOrderData({ ...orderData, order_detail: { ...orderData.order_detail, without_driver: true } });
      } else if (typeOrder === 'with-driver') {
        setOrderData({ ...orderData, order_detail: { ...orderData.order_detail, without_driver: false } });
      } else if (typeOrder === 'airport-transfer') {
        setOrderData({
          ...orderData,
          order_detail: { ...orderData.order_detail, without_driver: false },
          order_type_id: 2,
        });
      } else if (typeOrder === 'one-way') {
        setOrderData({
          ...orderData,
          order_detail: { ...orderData.order_detail, without_driver: false },
          order_type_id: 6,
        });
      }
    }

    setIsLoading(false);
  }, [pathLocation]);

  // SET ALL ZONE WHEN DATA ZONE CHANGING
  useEffect(() => {
    if (Object.keys(zoneList).length === 0 || !zoneList.list_zones) return;
    if (zoneList?.pagination.page > 1) {
      setAllZonesList((prevState) => [...prevState, ...zoneList.list_zones]);
    } else {
      setAllZonesList(zoneList.list_zones);
    }
  }, [zoneList]);

  // SET ORDER DATA TO LOCAL STORAGE
  useEffect(() => {
    if (
      !orderDataLocalStorage.order_data &&
      !orderDataLocalStorage.ktpImageLink &&
      !orderDataLocalStorage.simImageLink &&
      !orderDataLocalStorage.paymentImageLink &&
      !orderDataLocalStorage.startRentDate &&
      !orderDataLocalStorage.endRentDate &&
      !orderDataLocalStorage.selectedOvertime &&
      !orderDataLocalStorage.selectedPaymentType &&
      !orderDataLocalStorage.selectedCar &&
      !orderDataLocalStorage.driver &&
      !orderDataLocalStorage.duration &&
      !Object.keys(orderDataLocalStorage.deliveryLocation).length &&
      !Object.keys(orderDataLocalStorage.returnLocation).length &&
      !Object.keys(orderDataLocalStorage.summary_order_data).length
    )
      return;

    localStorage.setItem('order-data', JSON.stringify(orderDataLocalStorage));
  }, [orderDataLocalStorage]);
  // GET ALL VEHICLE PACKAGE AIRPORT TRANSFER
  useEffect(() => {
    if (
      !orderData.order_detail.start_booking_date ||
      !orderData.order_detail.start_booking_time ||
      !Object.keys(deliveryLocation).length ||
      !Object.keys(returnLocation).length ||
      !rentalLocationId
    )
      return;

    if (pathName === 'airport-transfer') {
      const params = {
        pickupTrip: `${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`,
        locationId: rentalLocationId.id,
        pickUpLocationId: deliveryLocation?.id,
        dropOffLocationId: returnLocation?.id,
      };

      dispatch(getAllVehicleAirportPackage(params));
      return;
    }
  }, [
    orderData.order_detail.start_booking_date,
    orderData.order_detail.start_booking_time,
    deliveryLocation,
    returnLocation,
    isSwitch,
    rentalLocationId,
  ]);

  // GET VEHICLE BY ID AIRPORT PACKAGE
  useEffect(() => {
    if (
      !orderData.airport_transfer_package_id ||
      orderData.order_detail.start_booking_date === '' ||
      orderData.order_detail.start_booking_time === ''
    )
      return;

    const pickupTrip = `${orderData.order_detail.start_booking_date} ${orderData.order_detail.start_booking_time}`;
    dispatch(
      getVehicleByIdAirportPackage({
        id: orderData.airport_transfer_package_id,
        pickupTrip,
        pickUpLocationId: deliveryLocation?.id,
        dropOffLocationId: returnLocation?.id,
      }),
    );
  }, [
    orderData?.airport_transfer_package_id,
    orderData.order_detail.start_booking_date,
    orderData.order_detail.start_booking_time,
    deliveryLocation,
    returnLocation,
    isSwitch,
  ]);

  // GET ONE WAY PACKAGES DATA
  useEffect(() => {
    if (!rentalLocationId || !startLocation.lat || !startLocation.lng || !endLocation.lat || !endLocation.lng) return;

    dispatch(
      getAllOneWayPackages({
        org_lat: startLocation.lat,
        org_lng: startLocation.lng,
        des_lat: endLocation.lat,
        des_lng: endLocation.lng,
        location_id: rentalLocationId.id,
      }),
    );
  }, [startLocation, endLocation, rentalLocationId]);

  // SET SHUTTLE AIRPORT WHEN SHUTTLE AIRPORT CHANGING
  // useEffect(() => {
  //   if (!Object.keys(shuttleAirportData).length || !shuttleAirportData.shuttle) return;
  //   if (shuttleData.some((item, idx) => item === shuttleAirportData?.shuttle[idx])) return;

  //   setShuttleData((prevState) => [...prevState, ...shuttleAirportData.shuttle]);
  // }, [shuttleAirportData]);

  // GET ALL ADDON
  useEffect(() => {
    if (
      !rentalLocationId ||
      !orderData.order_detail.start_booking_date ||
      !orderData.order_detail.end_booking_date ||
      !orderData.order_detail.start_booking_time ||
      !orderData.order_detail.end_booking_time
    )
      return;

    dispatch(
      getAllAddonByParams({
        locationId: rentalLocationId.id,
        locTimeId: rentalLocationId.time_zone_identifier,
        startDate: orderData.order_detail.start_booking_date,
        endDate: orderData.order_detail.end_booking_date,
        startTime: orderData.order_detail.start_booking_time + ':00',
        endTime: orderData.order_detail.end_booking_time + ':00',
      }),
    );
  }, [
    rentalLocationId,
    orderData.order_detail.start_booking_date,
    orderData.order_detail.end_booking_date,
    orderData.order_detail.start_booking_time,
    orderData.order_detail.end_booking_time,
  ]);

  return (
    <div className="custom-order">
      <ProgressBar page={page} pageData={progressBarPage} prevPage={prevPage} />

      {/* FORM */}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="custom-order__form-container">
          {page === 1 && (
            <TableWrapper
              icon={
                pathName === 'airport-transfer' ? (
                  <AirportLogoIcon width="25px" height="25px" />
                ) : pathName === 'one-way' ? (
                  <OneWayLogoIcon width="25px" height="25px" />
                ) : (
                  <VehicleLogoIcon width="25px" height="25px" />
                )
              }
              title={
                pathName === 'airport-transfer'
                  ? 'Custom Airport Transfer'
                  : pathName === 'one-way'
                  ? 'One Way'
                  : 'Custom Sewa Mobil'
              }
            >
              <DetailPenyewa
                nextPage={nextPage}
                orderData={orderData}
                setOrderData={setOrderData}
                depositBank={depositBank}
                setDepositBank={setDepositBank}
                depositAccountNumber={depositAccountNumber}
                setDepositAccountNumber={setDepositAccountNumber}
                ktpImageLink={ktpImageLink}
                setKtpImageLink={setKtpImageLink}
                simImageLink={simImageLink}
                setSimImageLink={setSimImageLink}
                orderDataLocalStorage={orderDataLocalStorage}
                setOrderDataLocalStorage={setOrderDataLocalStorage}
              />
            </TableWrapper>
          )}
          {page === 2 && (
            <TableWrapper
              icon={
                pathName === 'airport-transfer' ? (
                  <AirportLogoIcon width="25px" height="25px" />
                ) : pathName === 'one-way' ? (
                  <OneWayLogoIcon width="25px" height="25px" />
                ) : (
                  <VehicleLogoIcon width="25px" height="25px" />
                )
              }
              title={
                pathName === 'airport-transfer'
                  ? 'Custom Airport Transfer'
                  : pathName === 'one-way'
                  ? 'One Way'
                  : 'Custom Sewa Mobil'
              }
            >
              <DetailSewa
                prevPage={prevPage}
                nextPage={nextPage}
                orderData={orderData}
                setOrderData={setOrderData}
                vehicleInput={vehicleInput}
                setVehicleInput={setVehicleInput}
                selectedCar={selectedCar}
                setSelectedCar={setSelectedCar}
                startRentDate={startRentDate}
                setStartRentDate={setStartRentDate}
                endRentDate={endRentDate}
                setEndRentDate={setEndRentDate}
                deliveryLocation={deliveryLocation}
                setDeliveryLocation={setDeliveryLocation}
                returnLocation={returnLocation}
                setReturnLocation={setReturnLocation}
                refferalCode={refferalCode}
                setRefferalCode={setRefferalCode}
                driver={driver}
                setDriver={setDriver}
                allVehicle={allVehicle}
                selectedOvertime={selectedOvertime}
                setSelectedOvertime={setSelectedOvertime}
                selectedPaymentType={selectedPaymentType}
                setSelectedPaymentType={setSelectedPaymentType}
                // with driver//
                durationRent={durationRent}
                setDurationRent={setDurationRent}
                dayDuration={dayDuration}
                setDayDuration={setDayDuration}
                allZonesList={allZonesList}
                orderDataLocalStorage={orderDataLocalStorage}
                setOrderDataLocalStorage={setOrderDataLocalStorage}
                isSwitch={isSwitch}
                setIsSwitch={setIsSwitch}
                // shuttleData={shuttleData}
                rentalLocationId={rentalLocationId}
                setRentalLocationId={setRentalLocationId}
                selectedAdditionalItem={selectedAdditionalItem}
                setSelectedAdditionalItem={setSelectedAdditionalItem}
                startLocation={startLocation}
                setStartLocation={setStartLocation}
                endLocation={endLocation}
                setEndLocation={setEndLocation}
                locationData={locationData}
                setLocationData={setLocationData}
              />
            </TableWrapper>
          )}
          {page === 3 && (
            <TableWrapper
              icon={
                pathName === 'airport-transfer' ? (
                  <AirportLogoIcon width="25px" height="25px" />
                ) : pathName === 'one-way' ? (
                  <OneWayLogoIcon width="25px" height="25px" />
                ) : (
                  <VehicleLogoIcon width="25px" height="25px" />
                )
              }
              title={
                pathName === 'airport-transfer'
                  ? 'Custom Airport Transfer'
                  : pathName === 'one-way'
                  ? 'One Way'
                  : 'Custom Sewa Mobil'
              }
            >
              <MetodePembayaran
                prevPage={prevPage}
                nextPage={nextPage}
                orderData={orderData}
                setOrderData={setOrderData}
                paymentImageLink={paymentImageLink}
                setPaymentImageLink={setPaymentImageLink}
                orderDataLocalStorage={orderDataLocalStorage}
                setOrderDataLocalStorage={setOrderDataLocalStorage}
                rentalLocationId={rentalLocationId}
              />
            </TableWrapper>
          )}
          {page === 4 && (
            <KonfirmasiPesanan
              prevPage={prevPage}
              nextPage={nextPage}
              orderData={orderData}
              setOrderData={setOrderData}
              // detail penyewa //
              depositBank={depositBank}
              setDepositBank={setDepositBank}
              depositAccountNumber={depositAccountNumber}
              setDepositAccountNumber={setDepositAccountNumber}
              ktpImageLink={ktpImageLink}
              setKtpImageLink={setKtpImageLink}
              simImageLink={simImageLink}
              setSimImageLink={setSimImageLink}
              // detail sewa //
              vehicleInput={vehicleInput}
              setVehicleInput={setVehicleInput}
              selectedCar={selectedCar}
              setSelectedCar={setSelectedCar}
              startRentDate={startRentDate}
              setStartRentDate={setStartRentDate}
              endRentDate={endRentDate}
              setEndRentDate={setEndRentDate}
              deliveryLocation={deliveryLocation}
              setDeliveryLocation={setDeliveryLocation}
              returnLocation={returnLocation}
              setReturnLocation={setReturnLocation}
              driver={driver}
              setDriver={setDriver}
              allVehicle={allVehicle}
              selectedPaymentType={selectedPaymentType}
              setSelectedPaymentType={setSelectedPaymentType}
              // selectedAdditionalItem={selectedAdditionalItem}
              // setSelectedAdditionalItem={setSelectedAdditionalItem}
              // with driver//
              durationRent={durationRent}
              setDurationRent={setDurationRent}
              dayDuration={dayDuration}
              setDayDuration={setDayDuration}
              allZonesList={allZonesList}
              // metode pembayaran //
              refferalCode={refferalCode}
              setRefferalCode={setRefferalCode}
              paymentImageLink={paymentImageLink}
              setPaymentImageLink={setPaymentImageLink}
              selectedOvertime={selectedOvertime}
              setSelectedOvertime={setSelectedOvertime}
              orderDataLocalStorage={orderDataLocalStorage}
              setOrderDataLocalStorage={setOrderDataLocalStorage}
              // airport transfer//
              isSwitch={isSwitch}
              setIsSwitch={setIsSwitch}
              // shuttleData={shuttleData}
              selectedAdditionalItem={selectedAdditionalItem}
              setSelectedAdditionalItem={setSelectedAdditionalItem}
              rentalLocationId={rentalLocationId}
              setRentalLocationId={setRentalLocationId}
              startLocation={startLocation}
              setStartLocation={setStartLocation}
              endLocation={endLocation}
              setEndLocation={setEndLocation}
              locationData={locationData}
              setLocationData={setLocationData}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CustomOrderComponent;
