import { createSlice } from '@reduxjs/toolkit';
import {
  getAllRefund,
  getDetailRefund,
  updateStatusRefund,
  getRefundHistories,
  getRefundReports,
  uploadImageRefund,
} from './actions';

const initialState = {
  data: {},
  selected: {},
  imageTransfer: {},
  histories: {},
  transferImage: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const refund = createSlice({
  name: 'refund',
  initialState,
  reducers: {
    // reducers goes here

    resetImageTransferRefund: (state) => {
      state.imageTransfer = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRefund.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllRefund.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllRefund.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getDetailRefund.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getDetailRefund.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailRefund.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selected = action.payload;
      })
      .addCase(updateStatusRefund.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(updateStatusRefund.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateStatusRefund.fulfilled, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getRefundHistories.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.histories = {};
      })
      .addCase(getRefundHistories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getRefundHistories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.histories = action.payload;
      })
      .addCase(getRefundReports.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = null;
      })
      .addCase(getRefundReports.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(getRefundReports.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadImageRefund.pending, (state) => {
        state.isError = false;
        state.errorMessage = {};
        state.imageTransfer = {};
      })
      .addCase(uploadImageRefund.rejected, (state, action) => {
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(uploadImageRefund.fulfilled, (state, action) => {
        state.imageTransfer = action.payload;
      });
  },
});

export const { resetImageTransferRefund } = refund.actions;

export default refund.reducer;
