import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { format } from 'date-fns';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllRefund = createAsyncThunk('refund/getAllRefund', async (params, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { page, limit, status } = params;

  let newUrl = `${BASE_URL}/${API_VERSION}/admin/refund-orders`;

  if (page) {
    if (newUrl.includes('?')) {
      newUrl += `&page=${page}`;
    } else {
      newUrl += `?page=${page}`;
    }
  }

  if (limit) {
    if (newUrl.includes('?')) {
      newUrl += `&limit=${limit}`;
    } else {
      newUrl += `?limit=${limit}`;
    }
  }

  if (status && status.length !== 0) {
    for (let statusChild of status) {
      newUrl += `&status=${statusChild}`;
    }
  }

  try {
    const response = await axios.get(newUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const getDetailRefund = createAsyncThunk('refund/getDetailRefund', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.get(`${BASE_URL}/${API_VERSION}/admin/refund-orders/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const updateStatusRefund = createAsyncThunk('refund/updateStatusRefund', async (payload, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { id, ...payloadData } = payload;

  try {
    const response = await axios.put(
      `${BASE_URL}/${API_VERSION}/admin/refund-orders/${id}`,
      { ...payloadData },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getRefundHistories = createAsyncThunk('refund/getRefundHistories', async (params, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  const { id, page, limit } = params;

  let newUrl = `${BASE_URL}/${API_VERSION}/admin/refund-orders/${id}/histories`;

  if (page) {
    if (newUrl.includes('?')) {
      newUrl += `&page=${page}`;
    } else {
      newUrl += `?page=${page}`;
    }
  }

  if (limit) {
    if (newUrl.includes('?')) {
      newUrl += `&limit=${limit}`;
    } else {
      newUrl += `?limit=${limit}`;
    }
  }

  try {
    const response = await axios.get(newUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const getRefundReports = createAsyncThunk('refund/getRefundReports', async ({ status }, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  let mainUrl = `${BASE_URL}/${API_VERSION}/admin/refund-orders/export`;

  if (status !== undefined) {
    status.map((item, idx) => {
      mainUrl += `${idx == 0 ? '?' : '&'}status[]=${item}`;
    });
  } else if (status === undefined) {
    mainUrl += `?status[]=CREATED&status[]=PROCESSED&status[]=REJECTED&status[]=TRANSFERED`;
  }

  try {
    const res = await axios.get(mainUrl, {
      headers: { Authorization: `Bearer ${accessToken}` },
      responseType: 'blob',
    });
    const dateNow = format(new Date(), 'MM-dd-yyyy-hh-mm-ss');
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = `deposit-reports-${dateNow}.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const importRefundReports = createAsyncThunk('refund/importRefundReports', async (file, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    await axios.post(`${BASE_URL}/${API_VERSION}/admin/refund-orders/import`, file, {
      headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' },
    });
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const uploadImageRefund = createAsyncThunk('refund/uploadImageRefund', async (file, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.post(`${BASE_URL}/${API_VERSION}/profile/document`, file, {
      headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' },
    });
    const data = await response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
