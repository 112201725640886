import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { useSearchParams } from 'react-router-dom';
import { setCurrency } from 'utils/functionality';
import { approvalWithdraw, getDetailWithdraw } from 'features/drivers/actions';
import { ReactComponent as CarIcon } from '../../../icons/car-blue-bg.svg';
import Modals from '../Modals';
import { Button } from 'components/Global';

const WithdrawFeeModal = () => {
  const dispatch = useDispatch();

  // context
  const { setShowWithdrawFeeModal, setShowTransferWithdrawFee, showToast } = useAppContext();

  const { selected: selectedDriversData } = useSelector((state) => state.drivers);
  const { selected: selectedWithdrawData } = useSelector((state) => state.withdraw);

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const continueHandler = async () => {
    setShowWithdrawFeeModal(false);
    setShowTransferWithdrawFee(true);

    try {
      await dispatch(approvalWithdraw({ id, status: 'process' })).unwrap();
      setShowWithdrawFeeModal(false);
      if (!selectedWithdrawData.withdraw.proof_of_transfer) {
        setShowTransferWithdrawFee(true);
      }
      dispatch(getDetailWithdraw(id));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  };

  return (
    <Modals
      setState={setShowWithdrawFeeModal}
      title="Withdraw Fee Driver"
      icon={<CarIcon width="25px" height="25px" />}
    >
      <div className="withdraw-fee">
        <h2>Penarikan Dana</h2>
        <p className="desc">
          Unutuk penarikan dana driver bisa melalui transfer manual yang sesuai data masing-masing dari driver Get &
          Ride. Saldo akan ter-reset setelah anda menyelesaikan proses ini
        </p>
        <div className="account-data">
          <div>
            <p>Nama Pemilik rekening :</p>
            <p>Nama Bank :</p>
            <p>Nomor Rekening :</p>
            <p>Nominal Fee :</p>
          </div>
          <div>
            <p>{selectedDriversData?.account_bank?.nama_rek || '-'}</p>
            <p>{selectedDriversData?.account_bank?.nama_bank || '-'}</p>
            <p>{selectedDriversData?.account_bank?.no_rek || '-'}</p>
            <p>
              {selectedWithdrawData?.withdraw?.amount
                ? setCurrency(selectedWithdrawData.withdraw.amount).replace(
                    'Rp',
                    selectedDriversData?.user_location?.currency ? selectedDriversData?.user_location?.currency : '',
                  )
                : '-'}
            </p>
          </div>
        </div>
        <div className="action-btn">
          <Button variant="outline" width={131} onClick={() => setShowWithdrawFeeModal(false)}>
            Kembali
          </Button>
          <Button width={131} onClick={continueHandler}>
            Lanjutkan
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default WithdrawFeeModal;
