import { createSlice } from '@reduxjs/toolkit';
import { getAllOneWayPackages } from './actions';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  errorMessage: {}, // kalo retry setelah error, harus hapus errorMessage dan isError!
};

export const oneWayPackages = createSlice({
  name: 'oneWayPackages',
  initialState,
  reducers: {
    // reducers goes here
    resetOneWayPackageData: (state) => {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOneWayPackages.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllOneWayPackages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllOneWayPackages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

export const { resetOneWayPackageData } = oneWayPackages.actions;
export default oneWayPackages.reducer;
