import React from 'react';
import SelectFieldShuttle from 'components/Global/SelectFieldShuttle';
import { useSelector } from 'react-redux';
import { Button, InputField } from 'components/Global';
import { useAppContext } from 'components/Context/AppContext';
import BlueMappin from '../../../../../../icons/mappin-icon-blue.svg';
import YellowMappin from '../../../../../../icons/mappin-icon-yellow.svg';
import { ReactComponent as RedMappin } from '../../../../../../icons/mappin-icon-red.svg';
import { ReactComponent as LocationNotesIcon } from '../../../../../../icons/location-notes-icon.svg';
import SelectFieldWithMap from 'components/Global/SelectFieldWithMap';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldTime from 'components/Global/SelectFieldTime';
import { format } from 'date-fns';
import SelectFieldOneWayPackage from 'components/Global/SelectFieldOneWayPackage';

const OneWayForm = ({
  prevPage,
  nextPage,
  orderData,
  setOrderData,
  startRentDate,
  setStartRentDate,
  setEndRentDate,
  isOrderConfirmation,
  rentalLocationId,
  setRentalLocationId,
  startLocation,
  setStartLocation,
  endLocation,
  setEndLocation,
  locationData,
  setLocationData,
}) => {
  // const dispatch = useDispatch();
  const { showToast } = useAppContext();

  const provider = new OpenStreetMapProvider();

  // GLOBAL STATE
  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  // const { data: rentalServicesData } = useSelector((state) => state.services);
  const { data: oneWayPackagesData } = useSelector((state) => state.oneWayPackages);

  const submitHandler = async () => {
    // validation
    const validRentalLocation = !rentalLocationId;
    const validDeliveryLocation = startLocation.name.trim() !== '';
    const validReturnLocation = endLocation.name.trim() !== '';
    const validDetailDeliveryLocation = orderData.order_detail.rental_delivery_location_detail.trim() !== '';
    const validDetailReturnLocation = orderData.order_detail.rental_return_location_detail.trim() !== '';
    const validStartBookingDate = orderData.order_detail.start_booking_date !== '';
    const validStartBookingTime = orderData.order_detail.start_booking_time !== '';
    const validDriverFee = isNaN(orderData.service_fee) || orderData.service_fee === '';
    const validPackage = orderData.order_detail.package_id;

    // check validation
    if (validRentalLocation) {
      return showToast({ type: 'error', message: 'Pilih Daerah' });
    } else if (!validStartBookingDate) {
      return showToast({ type: 'error', message: 'Pilih Tanggal' });
    } else if (!validStartBookingTime) {
      return showToast({ type: 'error', message: 'Pilih Jam Jemput' });
    } else if (!validDeliveryLocation) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Penjemputan' });
    } else if (!validReturnLocation) {
      return showToast({ type: 'error', message: 'Pilih Lokasi Tempat Tujuan' });
    } else if (!validDetailDeliveryLocation) {
      return showToast({ type: 'error', message: 'Masukan Detail Lokasi Penjemputan' });
    } else if (!validDetailReturnLocation) {
      return showToast({ type: 'error', message: 'Masukan Detail Lokasi Tempat Tujuan' });
    } else if (validDriverFee) {
      return showToast({ type: 'error', message: 'Masukan Fee Driver' });
    } else if (!validPackage) {
      return showToast({ type: 'error', message: 'Pilih Package Mobil' });
    }

    nextPage();
  };

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    // function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  // SEARCH LOCATION BY INPUT
  const searchFunc = async (input) => {
    const results = await provider
      .search({ query: input })
      .then((res) =>
        res?.length > 0
          ? setLocationData({ latlng: [res[0].y, res[0].x], bounds: res[0].bounds })
          : setLocationData({ latlng: [-8.2271303, 115.1919203], bounds: null }),
      )
      .catch(() => setLocationData({ latlng: [-8.2271303, 115.1919203], bounds: null }));
    return results;
  };

  return (
    <div className="one-way-form">
      <div className="one-way-form__form">
        <SelectFieldShuttle
          label="Pilih Daerah"
          htmlFor="lokasi-rental"
          data={rentalLocationData}
          value={rentalLocationId.id}
          onChange={(item) => {
            setRentalLocationId(item);
            setStartLocation({
              name: '',
              lat: 0,
              lng: 0,
            });
            setEndLocation({
              name: '',
              lat: 0,
              lng: 0,
            });
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, package_id: '' },
            });
            searchFunc(item.name);
          }}
          placeholder="Pilih Daerah"
          searchFeature
          disable={isOrderConfirmation}
          icon={<RedMappin className="select-field-shuttle__input-field__arrow" />}
        />

        <div className="one-way-form__form__baggage-passenger">
          <div className="start-date">
            {/* START DATE */}
            <SelectFieldDate
              label="Tanggal"
              htmlFor="tanggal-mulai"
              name="tanggal-mulai"
              handleDaySelect={(date) => {
                if (date) {
                  setStartRentDate(date);
                  setEndRentDate('');
                  setOrderData({
                    ...orderData,
                    order_detail: {
                      ...orderData.order_detail,
                      start_booking_date: format(date, 'yyyy-MM-dd').toString(),
                    },
                  });
                }
              }}
              selectedDay={startRentDate}
              value={startRentDate !== '' ? format(startRentDate, 'dd-MM-yyyy') : startRentDate}
              placeholder="Pilih Tanggal"
              disable={isOrderConfirmation}
              dateContainerXOffset="left"
            />
          </div>

          {/* START TIME */}
          <SelectFieldTime
            label="Jam Jemput"
            htmlFor="jam-mulai"
            placeholder="00:00"
            value={orderData.order_detail.start_booking_time}
            onChange={(hour, minute) => {
              setOrderData({
                ...orderData,
                order_detail: {
                  ...orderData.order_detail,
                  start_booking_time: `${hour}:${minute ? minute : '00'}`,
                },
              });
            }}
            disable={isOrderConfirmation ? true : startRentDate === '' ? true : false}
            showAllHours={true}
          />
        </div>
      </div>

      <div className="one-way-form__form">
        <div className="one-way-form__form__shuttle">
          <SelectFieldWithMap
            label="Lokasi Penjemputan"
            htmlFor="lokasi-penjemputan"
            placeholder="Masukan Lokasi Penjemputan"
            iconUrl={BlueMappin}
            value={startLocation.name}
            setValue={setStartLocation}
            centerCoord={locationData.latlng}
            positionCoord={[startLocation.lat, startLocation.lng]}
            onClick={(item) => {
              setStartLocation({ name: item.label, lat: item.y, lng: item.x });
              setOrderData({
                ...orderData,
                order_detail: { ...orderData.order_detail, package_id: '' },
              });
            }}
            showMap={rentalLocationId.name}
            mapBounds={locationData.bounds}
            disable={isOrderConfirmation ? true : !rentalLocationId.id}
          />
          <SelectFieldWithMap
            label="Lokasi Tempat Tujuan"
            htmlFor="lokasi-tujuan"
            placeholder="Masukan Lokasi Tempat Tujuan"
            iconUrl={YellowMappin}
            value={endLocation.name}
            setValue={setEndLocation}
            centerCoord={locationData.latlng}
            positionCoord={[endLocation.lat, endLocation.lng]}
            onClick={(item) => {
              setEndLocation({ name: item.label, lat: item.y, lng: item.x });
              setOrderData({
                ...orderData,
                order_detail: { ...orderData.order_detail, package_id: '' },
              });
            }}
            showMap={rentalLocationId.name}
            mapBounds={locationData.bounds}
            disable={isOrderConfirmation ? true : !rentalLocationId}
          />
        </div>

        {/* DETAIL DELIVERY LOCATION */}
        <InputField
          label="Detail Lokasi"
          htmlFor="detail-lokasi-penjemputan"
          placeholder="Tulis detail lokasi"
          value={orderData.order_detail.rental_delivery_location_detail}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, rental_delivery_location_detail: e.target.value },
            })
          }
          disable={isOrderConfirmation}
          icon={<LocationNotesIcon />}
          iconPosition="start"
        />

        {/* DETAIL RETURN LOCATION */}
        <InputField
          label="Detail Lokasi"
          htmlFor="detail-lokasi-pengantaran"
          placeholder="Tulis detail lokasi"
          value={orderData.order_detail.rental_return_location_detail}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              order_detail: { ...orderData.order_detail, rental_return_location_detail: e.target.value },
            })
          }
          disable={isOrderConfirmation}
          icon={<LocationNotesIcon />}
          iconPosition="start"
        />
      </div>

      <div className="one-way-form__driver-fee">
        <InputField
          type="number"
          label="Harga Order"
          htmlFor="order-price"
          placeholder={`${rentalLocationId?.currency || ''} 0`}
          value={orderData.total_payment || ''}
          onChange={(e) =>
            setOrderData({
              ...orderData,
              total_payment: isNaN(Number(e.target.value)) ? '' : Number(e.target.value),
            })
          }
          disable={isOrderConfirmation}
          onWheel={numberInputOnWheelPreventChange}
        />
        {/* PACKAGE VEHICLE */}
        <SelectFieldOneWayPackage
          label="Tipe Paket Mobil"
          htmlFor="pilih-package-mobil"
          placeholder="Pilih Paket Mobil"
          data={oneWayPackagesData?.data}
          value={orderData?.order_detail?.package_id}
          onChange={(e) => {
            setOrderData({
              ...orderData,
              order_detail: {
                ...orderData.order_detail,
                package_id: +e.currentTarget.id,
              },
            });
          }}
          disable={
            isOrderConfirmation
              ? true
              : !rentalLocationId || !startLocation.lat || !startLocation.lng || !endLocation.lat || !endLocation.lng
          }
        />
      </div>

      {/* ACTION BUTTON */}
      {!isOrderConfirmation && (
        <div className="one-way-form__button-action">
          <div className="one-way-form__button-action__action">
            <Button
              width="165px"
              height="39px"
              variant="outline"
              size="md"
              className="button"
              onClick={() => prevPage()}
              type="button"
            >
              Kembali
            </Button>
            <Button type="button" width="165px" height="39px" size="md" className="button" onClick={submitHandler}>
              Lanjutkan
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OneWayForm;
