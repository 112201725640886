import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllOneWayPackages = createAsyncThunk(
  'oneWayPackages/getAllOneWayPackages',
  async ({ org_lat, org_lng, des_lat, des_lng, location_id }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/${API_VERSION}/packages?origin_latitude=${org_lat}&origin_longitude=${org_lng}&dest_latitude=${des_lat}&dest_longitude=${des_lng}&location_id=${location_id}`,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
