import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { useLocation } from 'react-router-dom';
import { getDetailRefund, updateStatusRefund } from 'features/refund/actions';
import Modals from '../Modals';
import { Button } from 'components/Global';
import { ReactComponent as RefundIcon } from 'icons/refund-filled-icon.svg';

const RefundProcessModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const id = location.pathname.split('/')[2];

  const { selected } = useSelector((state) => state.refund);

  // context
  const { setShowRefundProcess } = useAppContext();

  const continueHandler = async () => {
    const payload = {
      id,
      status: 'PROCESSED',
    };

    try {
      await dispatch(updateStatusRefund(payload)).unwrap();
      dispatch(getDetailRefund(id));
      setShowRefundProcess(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <Modals setState={setShowRefundProcess} title="Konfirmasi" icon={<RefundIcon width="25px" height="25px" />}>
      <div className="refund-process">
        <h2>Pengembalian Dana Refund</h2>
        <p className="desc">
          Silakan lanjutkan dan mengembalikan dana melalui manual bank transfer sesuai data di bawah ini, jika tidak ada
          kesesuaian atau data tersebut tidak lengkap mohon di informasikan kepada customer agar bisa dilakukan proses
          pengembalian dana.
        </p>
        <div className="account-data">
          <div>
            <p>Nama Pemilik rekening :</p>
            <p>Nama Bank :</p>
            <p>Nomor Rekening :</p>
          </div>
          <div>
            <p>{selected?.customer_bank_account_name || '-'}</p>
            <p>{selected?.customer_bank_name || '-'}</p>
            <p>{selected?.customer_bank_number || '-'}</p>
          </div>
        </div>
        <div className="action-btn">
          <Button variant="outline" width={131} onClick={() => setShowRefundProcess(false)}>
            Kembali
          </Button>
          <Button width={131} onClick={continueHandler}>
            Konfirmasi
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default RefundProcessModal;
