import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useAppContext } from 'components/Context/AppContext';
import { getDetailRefund } from 'features/refund/actions';
import { Button, TableWrapper } from 'components/Global';
import RefundDetailForm from './RefundDetailForm';
import RefundHistory from './RefundHistory';
import PreviewImage from 'components/Modals/PreviewImage';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as RefundIcon } from 'icons/refund-filled-icon.svg';

const DetailRefundComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const id = location.pathname.split('/')[2];

  const { setShowSpinner } = useAppContext();

  const { isLoading } = useSelector((state) => state.refund);

  const [section, setSection] = useState('detail');
  const [showImage, setShowImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const backHandler = () => {
    navigate(-1);
  };

  const changeSectionHandler = (section) => {
    setSection(section);
  };

  useEffect(() => {
    dispatch(getDetailRefund(id));
  }, [id]);

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  return (
    <>
      <div className="detail-refund-dana">
        <header className="detail-refund-dana__header" onClick={backHandler}>
          <LeftArrow />
          <p>Kembali</p>
        </header>

        <TableWrapper icon={<RefundIcon fill="#009EF7" width="25px" height="25px" />} title="Refund Dana">
          <h2>Detail Refund Dana</h2>
          <div className="detail-refund-dana__detail">
            <div className="section-btn">
              <Button
                onClick={() => changeSectionHandler('detail')}
                className={clsx('section-btn__button', section === 'detail' ? 'active' : '')}
              >
                Detail
              </Button>
              <Button
                onClick={() => changeSectionHandler('history')}
                className={clsx('section-btn__button', section === 'history' ? 'active' : '')}
              >
                History
              </Button>
            </div>

            {section === 'detail' ? (
              <RefundDetailForm setShowImage={setShowImage} setSelectedImage={setSelectedImage} />
            ) : (
              <RefundHistory setShowImage={setShowImage} setSelectedImage={setSelectedImage} />
            )}
          </div>
        </TableWrapper>
      </div>
      {showImage &&
        ReactDOM.createPortal(
          <PreviewImage image={selectedImage} setShow={() => setShowImage(false)} />,
          document.getElementById('modal'),
        )}
    </>
  );
};

export default DetailRefundComponent;
