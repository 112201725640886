import Sidebar from './sidebar';
import { ReactComponent as UserIcon } from 'icons/user-filled-icon.svg';
import { ChatMessage } from './chat-message';
import { useEffect, useRef, useState } from 'react';
import { getCookie } from 'utils/cookies';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { useAppContext } from 'components/Context/AppContext';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { convertImageToBase64 } from 'utils/functionality';
import { uploadFile } from 'features/orders/actions';

const MAX_FILE_SIZE = 1048576;
const WS_URL = process.env.REACT_APP_WEBSOCKET_URL;

const Messages = () => {
  const accessToken = getCookie('grbut');
  const adminProfile = useSelector((state) => state.user.profile);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const didUnmount = useRef(false);
  const messagesEndRef = useRef(null);
  const targetFile = useRef(null);

  const [selectedInbox, setSelectedInbox] = useState(null);
  const { showToast, setShowSpinner } = useAppContext();

  const [messages, setMessages] = useState({ messages: [], room: {} });
  const [inbox, setInbox] = useState([]);
  const [chatHistory, setChatHistory] = useState(null);
  const [unreadCount, setUnreadCount] = useState([]);
  const [inputText, setInputText] = useState('');
  const [fileToUpload, setFileToUpload] = useState({
    file: null,
    url: {},
  });

  const [chatView, setChatView] = useState('online');
  const chatType = searchParams.get('chatType') || 'cs-order';

  const { sendJsonMessage, readyState } = useWebSocket(WS_URL, {
    queryParams: {
      token: accessToken,
    },
    onOpen: () => {
      sendJsonMessage({
        key: 'GET_INBOX',
      });
      sendJsonMessage({
        key: 'GET_COUNT_UNREAD_BY_ROOM_TYPE',
      });
    },
    // shouldReconnect: didUnmount.current === false,
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    onMessage: (e) => {
      const parsedData = JSON.parse(e.data);
      if (parsedData.key === 'GET_INBOX') {
        setInbox(parsedData.data);
      }

      if (parsedData.key === 'GET_MESSAGES') {
        setMessages({ messages: parsedData.messages, room: parsedData.room });
        // setMessages(parsedData.messages);
      }

      if (parsedData.key === 'GET_HISTORY') {
        setChatHistory(parsedData.data);
      }

      if (parsedData.key === 'GET_COUNT_UNREAD_BY_ROOM_TYPE') {
        setUnreadCount(parsedData.data);
      }

      if (parsedData.key === 'NEW_MESSAGE') {
        sendJsonMessage({
          key: 'GET_COUNT_UNREAD_BY_ROOM_TYPE',
        });

        if (selectedInbox) {
          // const isExistingRoom = selectedInbox.room.id === parsedData.room.id;

          // if (isExistingRoom) {
          //   // setMessages((prevMessages) => [parsedData.message, ...prevMessages]);
          //   setMessages((prevMessages) => ({ ...prevMessages, messages: parsedData.message, room: parsedData.room }));
          // }

          sendJsonMessage({
            key: 'GET_MESSAGES',
            req_get_messages: {
              room_id: selectedInbox.room.id,
              limit: 50,
              page: 1,
            },
          });

          // if (parsedData.room.id === selectedInbox.room.id) return;

          sendJsonMessage({
            key: 'GET_COUNT_UNREAD_BY_ROOM_TYPE',
          });
        }

        sendJsonMessage({
          key: 'GET_INBOX',
        });
      }

      if (parsedData.key === 'END_SESSION') {
        sendJsonMessage({
          key: 'GET_INBOX',
        });
      }
    },
    // onClose: (event) => console.warn('WebSocket closed:', event),
    // onError: (event) => console.error('WebSocket error:', event),
    share: true,
  });

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  // useEffect(() => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  // }, [messages]);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (inputText.trim() === '' && fileToUpload.file === null) return;

    let response;

    if (fileToUpload.file) {
      try {
        setShowSpinner(true);
        response = await dispatch(uploadFile({ file: fileToUpload.file, name: 'image_from_chat' })).unwrap();
      } catch (error) {
        showToast({
          type: 'error',
          message: 'terjadi kesalahan!',
        });
      } finally {
        setShowSpinner(false);
      }
    }

    const payload = {
      key: 'SEND_MESSAGE',
      req_send_message: {
        message: inputText,
        room_id: selectedInbox.room.id,
        file: response?.image_from_chat || '',
        file_type: fileToUpload?.file?.type || '',
      },
    };

    sendJsonMessage(payload);
    sendJsonMessage({
      key: 'GET_INBOX',
    });

    setInputText('');
    setFileToUpload({ file: null, url: {} });
  };

  const handleSelectInbox = (item) => {
    if (readyState === 3) {
      return showToast({ type: 'info', message: 'Koneksi Terputus. Silakan coba lagi!' });
    }

    sendJsonMessage({
      key: 'GET_MESSAGES',
      req_get_messages: {
        room_id: item.room.id,
        limit: 50,
        page: 1,
      },
    });

    // update inbox is_read status when get selected
    sendJsonMessage({
      key: 'GET_INBOX',
    });

    // update unread count when get selected
    sendJsonMessage({
      key: 'GET_COUNT_UNREAD_BY_ROOM_TYPE',
    });

    setSelectedInbox(item);
  };

  const handleChatViewChange = (event) => {
    const selectedView = event.target.id;

    if (chatView === selectedView) return;

    if (selectedView === 'inbox') {
      sendJsonMessage({ key: 'GET_INBOX' });
    } else if (selectedView === 'history') {
      sendJsonMessage({
        key: 'GET_HISTORY',
        req_get_messages: { limit: 10, page: 1 },
      });
    }
    setChatView(selectedView);
    setSelectedInbox(null);
  };

  useEffect(() => {
    if (!searchParams.get('chatType')) {
      setSearchParams({ chatType: 'cs-order' });
    }
  }, [searchParams, setSearchParams]);

  const handleChatTypeChange = (event) => {
    const selectedType = event.target.id;

    if (chatType === selectedType) return;

    // setChatType(selectedType);
    setSearchParams({ chatType: selectedType });
    setSelectedInbox(null);
  };

  const handleEndSession = (roomId) => {
    sendJsonMessage({
      key: 'END_SESSION',
      req_send_message: {
        room_id: roomId,
      },
    });

    setSelectedInbox(null);
  };

  const handleStartSession = (roomId) => {
    sendJsonMessage({
      key: 'SEND_MESSAGE',
      req_send_message: {
        room_id: roomId,
        message: `Halo, ${messages.room.name}`,
        file: '',
        file_type: '',
      },
    });
  };

  const handleFileImg = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e?.target?.files[0];
      const base64 = await convertImageToBase64(selectedFile);
      if (selectedFile.size >= MAX_FILE_SIZE) {
        showToast({ type: 'error', message: 'Ukuran File Terlalu Besar' });
        return;
      }
      setFileToUpload({ file: selectedFile, url: base64 });
    }
  };

  return (
    <div className="chat">
      <Sidebar
        inbox={inbox}
        onInboxSelect={handleSelectInbox}
        selectedInbox={selectedInbox}
        chatView={chatView}
        chatType={chatType}
        chatHistory={chatHistory}
        unreadCount={unreadCount}
        onSelectView={handleChatViewChange}
        onSelectType={handleChatTypeChange}
      />

      {selectedInbox === null ? (
        <EmptyChat />
      ) : (
        <main className="chat__right-side">
          <div className="chat__header">
            <div className="chat__header__info">
              <div className="chat__header__user-icon">
                <UserIcon />
              </div>
              <div>
                <h2 className="chat__header__chat-id">{messages.room.name}</h2>
                <div className="chat__header__user-status">
                  <span
                    className={clsx('chat__header__user-status__badge', messages.room.is_active ? 'online' : 'closed')}
                  />
                  <p>{messages.room.is_active ? 'Online' : 'Closed'}</p>
                </div>
              </div>
            </div>
            {messages.room.is_active ? (
              <button className="chat__header__btn online" onClick={() => handleEndSession(messages.room.id)}>
                End Session
              </button>
            ) : null}

            {messages.room.is_active === false ? (
              <button className="chat__header__btn closed" onClick={() => handleStartSession(messages.room.id)}>
                Start New Session
              </button>
            ) : null}
          </div>

          <div className="chat__message">
            <div className="chat__message-overlay"></div>
            {messages?.messages
              // .slice()
              // .reverse()
              .map((message, index) => (
                <ChatMessage key={index} {...message} adminId={adminProfile.id} />
              ))}
            <div ref={messagesEndRef} />

            {fileToUpload.file && (
              <div className="chat__file-upload">
                <div className="chat__file-upload__img-wrapper">
                  <img
                    src={fileToUpload.url?.file}
                    width={200}
                    height={200}
                    style={{ height: 'auto' }}
                    className="object-cover"
                    alt="file to upload thumbnail"
                  />
                  <button onClick={() => setFileToUpload({ file: null, url: {} })} className="close-btn">
                    X
                  </button>
                </div>
                <div className="chat__file-upload__title line-clamp-1 break-words">
                  <p className="text-xs">{fileToUpload?.file?.name}</p>
                </div>
              </div>
            )}
            <input
              type="file"
              onChange={handleFileImg}
              accept="image/png, image/jpg, image/jpeg"
              ref={targetFile}
              name="file-to-upload"
              className="chat__input-file"
            />
          </div>

          {messages.room.is_active ? (
            <div className="chat__message-input">
              <div className="chat__message-upload-btn" onClick={() => targetFile?.current?.click()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M11.97 12v3.5c0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5V10c0-3.87-3.13-7-7-7s-7 3.13-7 7v6c0 3.31 2.69 6 6 6"
                  ></path>
                </svg>
              </div>
              <form className="chat__message-form" onSubmit={handleSendMessage}>
                <div className="chat__message-input-form">
                  <input
                    type="text"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    placeholder="Type a message"
                    className="chat__message-input-form__field"
                  />
                  <button className="chat__message-input-btn shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                      <path
                        fill="#009EF7"
                        d="m14.14.96-9.03 3c-6.07 2.03-6.07 5.34 0 7.36l2.68.89.89 2.68c2.02 6.07 5.34 6.07 7.36 0l3.01-9.02c1.34-4.05-.86-6.26-4.91-4.91m.32 5.38-3.8 3.82c-.15.15-.34.22-.53.22s-.38-.07-.53-.22a.754.754 0 0 1 0-1.06l3.8-3.82c.29-.29.77-.29 1.06 0s.29.77 0 1.06"
                      ></path>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          ) : null}
        </main>
      )}
    </div>
  );
};

export default Messages;

const EmptyChat = () => {
  return (
    <div className="empty-chat">
      <div className="empty-chat__wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="101" fill="none" viewBox="0 0 96 101">
          <path
            fill="#D5D5D5"
            fillRule="evenodd"
            d="M96 42.176c0-16.924 0-25.384-4.044-31.463a24 24 0 0 0-6.624-6.652C79.278 0 70.86 0 54 0H42C25.146 0 16.722 0 10.668 4.061a24 24 0 0 0-6.624 6.652C0 16.793 0 25.246 0 42.176 0 59.107 0 67.561 4.044 73.64a24 24 0 0 0 6.624 6.652C15.954 83.84 23.052 84.287 36 84.347l6.636 13.328c.499.999 1.265 1.84 2.212 2.427a5.984 5.984 0 0 0 8.516-2.428L60 84.354c12.948-.06 20.04-.512 25.332-4.061a24 24 0 0 0 6.624-6.652C96 67.56 96 59.107 96 42.176M30 24.101a5.99 5.99 0 0 0-4.243 1.765A6.04 6.04 0 0 0 24 30.126c0 1.598.632 3.13 1.757 4.26A5.99 5.99 0 0 0 30 36.152h36a5.99 5.99 0 0 0 4.243-1.765A6.04 6.04 0 0 0 72 30.127c0-1.598-.632-3.13-1.757-4.26A5.99 5.99 0 0 0 66 24.1zm0 24.1a5.99 5.99 0 0 0-4.243 1.765A6.04 6.04 0 0 0 24 54.226c0 1.599.632 3.131 1.757 4.261A5.99 5.99 0 0 0 30 60.252h18a5.99 5.99 0 0 0 4.243-1.765A6.04 6.04 0 0 0 54 54.227c0-1.598-.632-3.13-1.757-4.26A5.99 5.99 0 0 0 48 48.201z"
            clipRule="evenodd"
          ></path>
        </svg>
        <p>Belum Ada Chat Terpilih</p>
      </div>
    </div>
  );
};
