import React from 'react';
import DetailIcon from 'icons/detail-icon.svg';
import DeleteIcon from 'icons/delete-icon.svg';
import EditIcon from 'icons/edit-icon.svg';
import SuccessIcon from 'icons/success-icon.svg';
import SendIcon from 'icons/send-icon.svg';
import EyeIcon from 'icons/eye-icon.svg';
import Tooltip from 'components/Global/Tooltip';
import DownloadInvoiceIcon from 'icons/dowload-invoice-icon.svg';
import TransactionUpdateIcon from 'icons/trx-update-icon.svg';
import DriverIcon from 'icons/driver-icon.svg';
import CopyIcon from 'icons/copy-icon.svg';
import CancelIcon from 'icons/cancel-task-icon.svg';
import { useSelector } from 'react-redux';
import { checkPermission } from 'utils/functionality';

const ActionButtons = ({
  show,
  detail,
  edit,
  del,
  success,
  downloadInvoice,
  sendNotification,
  trxUpdate,
  driver,
  copy,
  cancel,
}) => {
  const { offCanvasMenu, currentMenu } = useSelector((state) => state.menu);
  return (
    <>
      {show && (
        <Tooltip content={show.state ? 'Hide' : 'Show'}>
          {show.state ? <img src={EyeIcon} onClick={show.setState} /> : <img src={EyeIcon} onClick={show.setState} />}
        </Tooltip>
      )}
      {downloadInvoice && (
        <Tooltip content="Download Invoice">
          <img src={DownloadInvoiceIcon} onClick={downloadInvoice} />
        </Tooltip>
      )}
      {copy && (
        <Tooltip content="Copy">
          <img src={CopyIcon} onClick={copy} />
        </Tooltip>
      )}
      {cancel && (
        <Tooltip content="Cancel">
          <img src={CancelIcon} onClick={cancel} />
        </Tooltip>
      )}
      {detail && (
        <Tooltip content="Detail">
          <img src={DetailIcon} onClick={detail} />
        </Tooltip>
      )}
      {edit && (
        <Tooltip content="Edit">
          <img src={EditIcon} onClick={edit} />
        </Tooltip>
      )}
      {del && checkPermission(offCanvasMenu, currentMenu, 'delete') && (
        <Tooltip content="Delete">
          <img src={DeleteIcon} onClick={del} />
        </Tooltip>
      )}
      {sendNotification && (
        <Tooltip content="Send Notification">
          <img src={SendIcon} onClick={sendNotification} />
        </Tooltip>
      )}
      {success && (
        <Tooltip content="Success">
          <img src={SuccessIcon} onClick={success} />
        </Tooltip>
      )}
      {trxUpdate && (
        <Tooltip content="Update">
          <img src={TransactionUpdateIcon} onClick={trxUpdate} />
        </Tooltip>
      )}
      {driver && (
        <Tooltip content="Driver">
          <img src={DriverIcon} onClick={driver} />
        </Tooltip>
      )}
    </>
  );
};

export default ActionButtons;
