import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch } from 'react-redux';
import { getDetailRefund, updateStatusRefund } from 'features/refund/actions';
import Modals from '../Modals';
import TextAreaInput from 'components/Global/TextArea';
import { Button } from 'components/Global';
import { ReactComponent as RefundIcon } from 'icons/refund-filled-icon.svg';

const RejectRefundModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const id = location.pathname.split('/')[2];

  // CONTEXT
  const { showToast, setShowRejectRefundModal } = useAppContext();

  // LOCAL STATE
  const [cancelMessage, setCancelMessage] = useState('');

  // SUBMIT ACTION
  const handleSubmit = async () => {
    // validation
    const validCancelMessage = cancelMessage.replace(/\s/g, '').length !== 0;

    // check if not valid return error toast
    if (!validCancelMessage) {
      return showToast({ type: 'error', message: 'Tulis Alasan Pembatalan' });
    }

    const payload = {
      id,
      status: 'REJECTED',
      rejected_reason: cancelMessage,
    };

    try {
      await dispatch(updateStatusRefund(payload)).unwrap();
      setShowRejectRefundModal(false);
      showToast({ type: 'success', message: 'Berhasil Tolak Permintaan Refund Dana' });
      dispatch(getDetailRefund(id));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  };

  return (
    <Modals
      setState={setShowRejectRefundModal}
      icon={<RefundIcon fill="#009EF7" width="25px" height="25px" />}
      title={'Alasan Pembatalan'}
    >
      <div className="reject-refund-modal">
        {/* TEXT AREA */}
        <div className="reject-refund-modal__message">
          <span>Tulis Keterangan</span>
          <TextAreaInput
            className="reject-refund-modal__message__textarea"
            style={{ height: '292px' }}
            value={cancelMessage}
            onChange={(e) => setCancelMessage(e.target.value)}
            placeholder="Masukkan Keterangan"
          />
        </div>

        {/* BUTTON */}
        <div className="reject-refund-modal__button">
          <Button type="button" width={131} variant="outline" size="md" onClick={() => setShowRejectRefundModal(false)}>
            Kembali
          </Button>
          <Button type="button" width={131} variant="primary" size="md" onClick={handleSubmit}>
            Kirim
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default RejectRefundModal;
