/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import PreviewImage from 'components/Modals/PreviewImage';
import { fetchCustomerInfo } from 'features/user/actions';
import EditOrder from './edit-order';
import DetailOrder from './detail-order';

const DetailOrderOneWay = () => {
  const { setShowSpinner } = useAppContext();
  const [showImage, setShowImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const { data, status } = useSelector((state) => state.detailOrder);
  const courierTask = useSelector((state) => state.courierTask?.selectedCourierTasks);
  const dispatch = useDispatch();
  const { transactionKey } = useParams();
  const [searchParams] = useSearchParams();
  const isUpdating = searchParams.get('update');

  /** Get Filename from given url path */
  const getFileName = (path = '') => (typeof path === 'string' ? path?.split('/')?.pop() : '-');

  /** Image Previewer */
  const imagePreviewHandler = (image) => {
    setShowImage(true);
    setSelectedImage(image);
  };

  useEffect(() => {
    if (status === 'loading') {
      setShowSpinner(true);
      return;
    }
    setShowSpinner(false);
  }, [status]);

  useEffect(() => {
    if (transactionKey) {
      dispatch(fetchCustomerInfo(searchParams.get('customerId')));
    }
  }, [transactionKey]);

  return (
    <>
      {isUpdating ? (
        <EditOrder transactionKey={transactionKey} />
      ) : (
        <DetailOrder
          getFileName={getFileName}
          imagePreviewHandler={imagePreviewHandler}
          orderData={data}
          transactionKey={transactionKey}
        />
      )}
      {showImage &&
        ReactDOM.createPortal(
          <PreviewImage image={selectedImage} setShow={() => setShowImage(false)} />,
          document.getElementById('modal'),
        )}
    </>
  );
};

export default DetailOrderOneWay;
