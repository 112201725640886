import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch } from 'react-redux';
import Modals from '../Modals';
import TextAreaInput from 'components/Global/TextArea';
import { Button } from 'components/Global';
import { ReactComponent as DriverIcon } from 'icons/driving-wheel-icon.svg';
import { approvalWithdraw, getDetailWithdraw } from 'features/drivers/actions';

const RejectWithdrawFeeModal = () => {
  const dispatch = useDispatch();

  // CONTEXT
  const { showToast, setShowRejectWithdrawFee } = useAppContext();

  // LOCAL STATE
  const [cancelMessage, setCancelMessage] = useState('');
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  // SUBMIT ACTION
  const handleSubmit = async () => {
    // validation
    const validCancelMessage = cancelMessage.replace(/\s/g, '').length !== 0;

    // check if not valid return error toast
    if (!validCancelMessage) {
      return showToast({ type: 'error', message: 'Tulis Alasan Pembatalan' });
    }

    const payload = {
      id,
      status: 'reject',
      cancelReason: cancelMessage,
    };

    try {
      await dispatch(approvalWithdraw(payload)).unwrap();
      setShowRejectWithdrawFee(false);
      showToast({ type: 'success', message: 'Berhasil Tolak Permintaan Withdraw' });
      dispatch(getDetailWithdraw(id));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  };

  return (
    <Modals
      setState={setShowRejectWithdrawFee}
      icon={<DriverIcon fill="#009EF7" width="25px" height="25px" />}
      title={'Alasan Pembatalan'}
    >
      <div className="reject-withdraw-fee">
        {/* TEXT AREA */}
        <div className="reject-withdraw-fee__message">
          <span>Tulis Keterangan</span>
          <TextAreaInput
            className="reject-withdraw-fee__message__textarea"
            style={{ height: '292px' }}
            value={cancelMessage}
            onChange={(e) => setCancelMessage(e.target.value)}
            placeholder="Masukkan Keterangan"
          />
        </div>

        {/* BUTTON */}
        <div className="reject-withdraw-fee__button">
          <Button type="button" width={131} variant="outline" size="md" onClick={() => setShowRejectWithdrawFee(false)}>
            Kembali
          </Button>
          <Button type="button" width={131} variant="primary" size="md" onClick={handleSubmit}>
            Kirim
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default RejectWithdrawFeeModal;
