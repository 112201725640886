import React from 'react';
import { InputField, SelectFieldDuration } from 'components/Global';
import { addDays, format } from 'date-fns';
import SelectFieldDate from 'components/Global/SelectFieldDate';
import SelectFieldTimeWithDriver from 'components/Global/SelectFieldTimeWithDriver';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import { ReactComponent as Calendar } from '../../../../../icons/calendar-icon.svg';
import { overtimeWithDriver } from '../../CustomOrderComponent/DetailSewa/WithDriverForm';

const DetailHari = ({
  setStartRentDate,
  startRentDate,
  setOrderData,
  orderData,
  setDurationRent,
  durationRent,
  setDayDuration,
  dayDuration,
  setSelectedCar,
  selectedCar,
  countOvertime,
}) => {
  return (
    <div className="detail-order-with-driver__rent-detail">
      <div className="detail-order-with-driver__rent-detail-title">
        <h1>Detail Hari</h1>
      </div>
      <div className="detail-order-with-driver__rent-detail-body">
        <SelectFieldDate
          label="Tanggal Mulai"
          htmlFor="tanggal-mulai"
          name="tanggal-mulai"
          handleDaySelect={(date) => {
            if (date) {
              setStartRentDate(date);
              setOrderData({
                ...orderData,
                order_detail: {
                  ...orderData.order_detail,
                  start_booking_date: format(date, 'yyyy-MM-dd').toString(),
                  end_booking_date: durationRent
                    ? format(addDays(date, durationRent - 1), 'yyyy-MM-dd').toString()
                    : '',
                },
              });
              setDayDuration((prev) =>
                prev.map((item, idx) => ({ ...item, date: format(addDays(date, idx), 'yyyy-MM-dd').toString() })),
              );
              setSelectedCar(undefined);
            }
          }}
          selectedDay={startRentDate}
          value={startRentDate !== '' ? format(startRentDate, 'dd MMMM yyyy') : startRentDate}
          placeholder="Pilih Tanggal"
        />
        <SelectFieldDuration
          label="Durasi Sewa"
          htmlFor="durasi-sewa"
          placeholder="0 Hari"
          startDate={startRentDate}
          value={durationRent}
          onClick={(duration) => {
            setDurationRent(duration);
            setOrderData({
              ...orderData,
              order_detail: {
                ...orderData.order_detail,
                end_booking_date: format(addDays(startRentDate, duration - 1), 'yyyy-MM-dd').toString(),
              },
            });

            if (dayDuration.length !== 0) {
              const copyDayDuration = [...dayDuration];
              const slicedCopyDayDuration = copyDayDuration.slice(0, duration);
              setDayDuration(slicedCopyDayDuration);
            }
          }}
          disable={startRentDate ? false : true}
        />
      </div>

      <div className="detail-order-with-driver__rent-detail-body__day-duration">
        {Array.from({ length: durationRent }, (_, i) => i + 1).map((_, idx) => {
          return (
            <div key={idx} className="with-driver-form__form__day">
              <div className="with-driver-form__form__time">
                <InputField
                  disable={true}
                  label={`Day ${idx + 1}`}
                  icon={<Calendar />}
                  iconPosition="start"
                  value={(!isNaN(startRentDate) && format(addDays(startRentDate, idx), 'dd-MM-yyyy')) || ''}
                  readOnly
                />
                {/* OVERTIME */}
                <SelectFieldDropdown
                  label="Tambahan Overtime"
                  htmlFor="tambahan-waktu-overtime"
                  value={dayDuration[idx]?.overtime_duration || ''}
                  data={overtimeWithDriver}
                  placeholder="Overtime"
                  disable={true}
                />
              </div>

              {/* JAM MULAI & JAM SELESAI */}
              <div className="with-driver-form__form__time">
                <SelectFieldTimeWithDriver
                  label="Jam Mulai"
                  htmlFor="jam-mulai"
                  placeholder="00:00"
                  value={dayDuration[idx]?.booking_start_time || ''}
                  onChange={(hour, minute) => {
                    if (idx == 0) {
                      setOrderData({
                        ...orderData,
                        order_detail: {
                          ...orderData.order_detail,
                          start_booking_time: `${hour}:${minute ? minute : '00'}`,
                        },
                      });
                    }
                    setDayDuration((prev) =>
                      prev[idx] === undefined
                        ? [
                            ...prev,
                            {
                              ...prev[idx],
                              booking_start_time: `${hour}:${minute ? minute : '00'}`,
                              booking_end_time: `${+hour + 12 > 23 ? 23 : +hour + 12}:${minute ? minute : '00'}`,
                            },
                          ]
                        : prev.map((item, day) =>
                            day === idx
                              ? {
                                  ...prev[idx],
                                  booking_start_time: `${hour}:${minute ? minute : '00'}`,
                                  booking_end_time:
                                    hour === 23
                                      ? '23:30'
                                      : // if end time - start time equal 12, set minute same with start time
                                      +prev[idx].booking_end_time?.split(':')[0] - hour === 12
                                      ? `${prev[idx].booking_end_time?.split(':')[0]}:${minute ? minute : '00'}`
                                      : // else end time not changed
                                        `${+hour + 12 > 23 ? 23 : +hour + 12}:${minute ? minute : '00'}`,
                                  overtime_duration: 'ot0',
                                }
                              : item,
                          ),
                    );
                  }}
                  disable={startRentDate === '' ? true : false}
                />
                <SelectFieldTimeWithDriver
                  label="Jam Selesai"
                  htmlFor="jam-selesai"
                  placeholder="00:00"
                  value={dayDuration[idx]?.booking_end_time || ''}
                  onChange={(hour, minute) => {
                    let overtimeValue = 0;
                    if (selectedCar) {
                      overtimeValue = countOvertime(
                        dayDuration[idx]?.booking_start_time,
                        `${hour}:${minute ? minute : '00'}`,
                        selectedCar.rental_duration,
                      );
                    }
                    if (idx == 0) {
                      setOrderData({
                        ...orderData,
                        order_detail: {
                          ...orderData.order_detail,
                          end_booking_time: `${hour}:${minute ? minute : '00'}`,
                        },
                      });
                    }
                    setDayDuration((prev) =>
                      prev[idx] === undefined
                        ? [
                            ...prev,
                            {
                              ...prev[idx],
                              booking_end_time: `${hour}:${minute ? minute : '00'}`,
                              overtime_duration:
                                overtimeValue > 0
                                  ? overtimeWithDriver.find((ovt) => ovt.value === overtimeValue)?.id
                                  : 'ot0',
                            },
                          ]
                        : prev.map((item, day) =>
                            day === idx
                              ? {
                                  ...prev[idx],
                                  booking_end_time: `${hour}:${minute ? minute : '00'}`,
                                  overtime_duration:
                                    overtimeValue > 0
                                      ? overtimeWithDriver.find((ovt) => ovt.value === overtimeValue)?.id
                                      : 'ot0',
                                }
                              : item,
                          ),
                    );
                  }}
                  disable={startRentDate === '' ? true : false}
                  startTime={dayDuration[idx]?.booking_start_time}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DetailHari;
