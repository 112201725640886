import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_URL_API;
const API_VERSION = process.env.REACT_APP_API_VERSION;

export const getAllRentalDuration = createAsyncThunk(
  'rentalDuration/getAllRentalDuration',
  async (params, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { page, limit } = params;

    let newUrl = `${BASE_URL}/${API_VERSION}/rental-duration-settings`;

    if (page) {
      if (newUrl.includes('?')) {
        newUrl += `&page=${page}`;
      } else {
        newUrl += `?page=${page}`;
      }
    }

    if (limit) {
      if (newUrl.includes('?')) {
        newUrl += `&limit=${limit}`;
      } else {
        newUrl += `?limit=${limit}`;
      }
    }

    try {
      const response = await axios.get(newUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const data = await response.data;
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const getDetailRentalDuration = createAsyncThunk(
  'rentalDuration/getDetailRentalDuration',
  async (id, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.get(`${BASE_URL}/${API_VERSION}/rental-duration-settings/${id}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const data = await response.data;
      return data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  },
);

export const createRentalDuration = createAsyncThunk(
  'rentalDuration/createRentalDuration',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/${API_VERSION}/rental-duration-settings`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const updateRentalDuration = createAsyncThunk(
  'rentalDuration/updateRentalDuration',
  async (payload, thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.data.access_token;

    const { id, ...payloadData } = payload;

    try {
      const response = await axios.put(
        `${BASE_URL}/${API_VERSION}/rental-duration-settings/${id}`,
        { ...payloadData },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const deleteRentalDuration = createAsyncThunk('rentalDuration/deleteRentalDuration', async (id, thunkAPI) => {
  const accessToken = thunkAPI.getState().auth.data.access_token;

  try {
    const response = await axios.delete(`${BASE_URL}/${API_VERSION}/rental-duration-settings/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    const data = await response.data;
    return data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
