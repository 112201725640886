import React, { useEffect, useMemo, useRef, useState } from 'react';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import useDebounce from 'utils/useDebounce';
import clsx from 'clsx';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import { useAppContext } from 'components/Context/AppContext';
import axios from 'axios';

const LocateMarker = ({
  position,
  setPosition,
  centerCoord,
  icon,
  mapBounds,
  setValue,
  reverseSearchFunc,
  disable,
}) => {
  const markerRef = useRef(null);
  const map = useMapEvents({
    async click(e) {
      if (disable) return;
      markerRef.current.setLatLng(e.latlng);
      const locatedLocation = await reverseSearchFunc({
        latitude: e.latlng.lat,
        longitude: e.latlng.lng,
        zoom: 18,
      });
      if (!locatedLocation) return;
      setValue({ name: locatedLocation?.label, lat: Number(locatedLocation?.y), lng: Number(locatedLocation?.x) });
      setPosition([Number(locatedLocation?.y), Number(locatedLocation?.x)]);
    },
  });
  const eventHandlers = useMemo(
    () => ({
      async dragend() {
        if (disable) return;
        const marker = markerRef.current;
        if (marker != null) {
          const locatedLocation = await reverseSearchFunc({
            latitude: marker.getLatLng().lat,
            longitude: marker.getLatLng().lng,
            zoom: 18,
          });
          if (!locatedLocation) return;
          setValue({ name: locatedLocation?.label, lat: Number(locatedLocation?.y), lng: Number(locatedLocation?.x) });
          setPosition([Number(locatedLocation?.y), Number(locatedLocation?.x)]);
        }
      },
    }),
    [],
  );

  useEffect(() => {
    map.flyTo(position, map.getZoom());
    markerRef.current.setLatLng(position);
  }, [position]);

  useEffect(() => {
    if (disable) return;
    map.setMaxBounds(mapBounds);
    map.flyTo(centerCoord, map.getZoom());
  }, [centerCoord]);

  return position ? (
    <Marker
      draggable={disable ? false : true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={icon}
    ></Marker>
  ) : null;
};

const SelectFieldWithMap = ({
  label,
  htmlFor,
  disable,
  placeholder,
  value,
  setValue,
  onClick,
  readOnly,
  iconUrl,
  centerCoord,
  positionCoord,
  showMap,
  mapBounds,
}) => {
  const provider = new OpenStreetMapProvider();

  const [data, setData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [position, setPosition] = useState(positionCoord);
  const [input, setInput] = useState('');
  const [isError, setIsError] = useState(false);

  const searchDebounce = useDebounce(input, 500);

  const { showToast } = useAppContext();

  const searchFunc = async () => {
    if (!input) return;
    setIsError(false);
    const results = await provider
      .search({ query: input })
      .then((res) => setData(res))
      .catch(() => setIsError(true));
    return results;
  };

  useEffect(() => {
    if (!searchDebounce) {
      setData([]);
      return;
    }
    searchFunc();
  }, [searchDebounce]);

  const onChangeHandler = (e) => {
    setIsSearching(true);
    setInput(e.target.value);
  };

  const onClickHandler = (e) => {
    onClick(e);
    setIsSearching(false);
    setPosition([e.y, e.x]);
  };

  const handleHide = () => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 200);
  };

  const searchReverseLocation = async ({ latitude, longitude, zoom = 18 }) => {
    let url = `${provider.reverseUrl}?lat=${latitude}&lon=${longitude}&zoom=${zoom}&format=json`;

    try {
      const response = await axios.get(url);
      return {
        x: response.data.lon,
        y: response.data.lat,
        label: response.data.display_name,
        bounds: response.data.boundingbox,
        raw: response.data,
      };
    } catch (error) {
      return showToast({ type: 'error', message: 'Error Dalam Mencari Lokasi ' + error.toString() });
    }
  };

  const myIcon = new L.icon({
    iconUrl: iconUrl,
    iconSize: [24, 36],
    iconAnchor: [18, 32],
  });

  useEffect(() => {
    if (isError) {
      showToast({ type: 'error', message: 'Error Dalam Mencari Lokasi' });
    }
  }, [isError]);

  return (
    <div className="input-field-map">
      <div className="g-input">
        <label htmlFor={htmlFor} className="input-text-title" style={{ textAlign: 'left' }}>
          {label}
        </label>
        <section className="field-wrapper with-icon" style={{ margin: 0 }}>
          <div className={`input-icon-left`}>{<img src={iconUrl} alt="input-img" />}</div>
          <input
            className={clsx('input-text-field with-icon-left', disable && 'disable')}
            type="text"
            id={htmlFor}
            placeholder={placeholder}
            value={isSearching ? input : value}
            onChange={onChangeHandler}
            readOnly={readOnly}
            disabled={disable}
            onFocus={() => setShowDropdown(true)}
            onBlur={handleHide}
          />
        </section>
        {showDropdown ? (
          <div className="list-place">
            {data?.map((item, idx) => (
              <div key={idx} onClick={() => onClickHandler(item)} className="list-place-item">
                {item.label}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {showMap ? (
        <MapContainer
          center={centerCoord}
          zoom={disable ? 15 : 10}
          minZoom={10}
          scrollWheelZoom={disable ? false : true}
          dragging={disable ? false : true}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <LocateMarker
            position={position}
            setPosition={setPosition}
            centerCoord={centerCoord}
            icon={myIcon}
            setValue={setValue}
            mapBounds={mapBounds}
            reverseSearchFunc={searchReverseLocation}
            disable={disable}
          />
        </MapContainer>
      ) : null}
    </div>
  );
};

export default SelectFieldWithMap;
