import React, { useState } from 'react';
import clsx from 'clsx';
import { setCurrency } from 'utils/functionality';
import { ReactComponent as ChevronDown } from '../../../icons/chevron-down.svg';

const SelectFieldOneWayPackage = ({
  label,
  name,
  htmlFor,
  value, // state
  onChange, // setState
  data = [],
  className,
  placeholder,
  disable,
  icon,
  disableDropdown,
  ...rest
}) => {
  // SHOW DROPDOWN FUNCTION
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  // FUNCTION TO HANDLE HIDE DROPDOWN
  const handleHide = () => {
    setTimeout(() => {
      setIsShowDropdown(false);
    }, 200);
  };

  const formatValue = (packageName, packagePrice, currency) =>
    `${packageName} : ${setCurrency(packagePrice, currency)} / km`;

  return (
    <div className={clsx('select-field-dropdown', className)}>
      {label && (
        <label htmlFor={htmlFor} className="select-field-dropdown__label">
          {label}
        </label>
      )}
      <div
        className={
          disable
            ? 'select-field-dropdown__input-field disable'
            : htmlFor === 'assign-to-driver'
            ? 'select-field-dropdown__input-field driver'
            : 'select-field-dropdown__input-field'
        }
        style={{ width: '100%' }}
      >
        {icon && icon}
        <input
          id={htmlFor}
          type="text"
          placeholder={placeholder}
          name={name}
          disabled={disable}
          className="select-field-dropdown__input-field__input"
          value={
            value
              ? data?.find((item) => item.id == value)?.name
                ? formatValue(
                    data?.find((item) => item.id == value)?.name,
                    data?.find((item) => item.id == value)?.price,
                    data?.find((item) => item.id == value)?.currency,
                  )
                : placeholder
              : placeholder
          }
          onFocus={() => setIsShowDropdown(true)}
          onBlur={handleHide}
          readOnly
          {...rest}
        />
        {disable ? null : (
          <ChevronDown
            className={
              !isShowDropdown
                ? 'select-field-dropdown__input-field__arrow'
                : 'select-field-dropdown__input-field__arrow down'
            }
            onFocus={() => setIsShowDropdown(true)}
            onBlur={handleHide}
            readOnly
            {...rest}
          />
        )}
      </div>

      {/* DROPDOWN */}
      {isShowDropdown && !disableDropdown && (
        <ul className="select-field-dropdown__dropdown">
          {data?.length !== 0 ? (
            data?.map((item) => (
              <li
                className="list"
                key={item.id}
                data-code={item.code ? item.code : null}
                id={item.id}
                onClick={onChange}
              >
                <p style={{ fontSize: 12 }}>
                  <span style={{ fontWeight: 'bold' }}>{item.name}</span> : {setCurrency(item.price, item?.currency)} /
                  km
                </p>
              </li>
            ))
          ) : (
            <li>No data found</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SelectFieldOneWayPackage;
