import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppContext } from 'components/Context/AppContext';
import { getDetailRefund, updateStatusRefund, uploadImageRefund } from 'features/refund/actions';
import { resetImageTransferRefund } from 'features/refund/slice';
import { checkEmptyObject } from 'utils/functionality';
import Modals from '../Modals';
import { Button, InputField } from 'components/Global';
import { ReactComponent as RefundIcon } from 'icons/refund-filled-icon.svg';

const RefundTransferModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const id = location.pathname.split('/')[2];

  // context
  const { setShowRefundTransferModal, setShowPreviewImageIdentity, showToast } = useAppContext();

  const { imageTransfer, isLoading } = useSelector((state) => state.refund);

  const targetRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);

  const onImageChange = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newImages = e.target.files;
    // check if there are files
    if (newImages) {
      for (let image of newImages) {
        const imageUrl = URL.createObjectURL(image);
        setImageFile({ image, imageUrl });
        dispatch(uploadImageRefund({ file: image }));
      }
    }

    e.target.value = '';
  };

  const deleteImageTransferHandler = () => {
    dispatch(resetImageTransferRefund());
    setImageFile(null);
  };

  const uploadTransfer = async () => {
    const payload = {
      id,
      status: 'TRANSFERED',
      proof_of_transfer_refund: !checkEmptyObject(imageTransfer) ? imageTransfer.file : undefined,
    };

    try {
      await dispatch(updateStatusRefund(payload)).unwrap();
      setShowRefundTransferModal(false);
      showToast({ type: 'success', message: 'Berhasil Proses Refund Dana' });
      dispatch(getDetailRefund(id));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: 'Gagal Proses Refund Dana' });
    }
  };

  useEffect(() => {
    dispatch(resetImageTransferRefund());
  }, []);

  return (
    <Modals
      setState={setShowRefundTransferModal}
      title="Bukti Transfer"
      icon={<RefundIcon width="25px" height="25px" />}
    >
      <div className="refund-transfer-modal">
        <h2>Bukti Transfer</h2>

        <div className="input-wrapper">
          <InputField value={imageFile?.image?.name || ''} readOnly label="Upload Bukti Transfer (Optional)" />
          <input
            type="file"
            name="upload-image"
            id="upload-image"
            accept="image/*"
            hidden
            ref={targetRef}
            onChange={onImageChange}
          />
          <div className="buttons">
            {!imageFile ? (
              <Button className="browse" width={66} height={25} onClick={() => targetRef.current.click()}>
                Browse
              </Button>
            ) : (
              <>
                <Button className="delete" width={66} height={25} onClick={deleteImageTransferHandler}>
                  Hapus
                </Button>
                <Button
                  className="view"
                  width={66}
                  height={25}
                  onClick={() => setShowPreviewImageIdentity({ image: imageFile.imageUrl, show: true })}
                >
                  Lihat
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="action-btn">
          <Button variant="outline" width={131} onClick={() => setShowRefundTransferModal(false)}>
            Kembali
          </Button>
          <Button
            type="button"
            width={149}
            onClick={uploadTransfer}
            disabled={isLoading || (imageFile && checkEmptyObject(imageTransfer))}
          >
            {isLoading || (imageFile && checkEmptyObject(imageTransfer)) ? 'Loading ...' : 'Simpan dan Tutup'}
          </Button>
        </div>
      </div>
    </Modals>
  );
};

export default RefundTransferModal;
