function isImage(fileType) {
  return fileType.startsWith('image/');
}

export const ChatMessage = ({ message, file, file_type, user_id, adminId }) => {
  return (
    <div
      className={`chat__message-item ${user_id === adminId ? 'chat__message-item__other' : 'chat__message-item__user'}`}
    >
      <div
        className={`chat__message-content ${
          user_id === adminId ? 'chat__message-content__other' : 'chat__message-content__user'
        }`}
      >
        {/* {message ? message : null} */}
        {message}
        {file && isImage(file_type) ? (
          <div className="chat__message-content__image">
            <img src={'https://itcdevbucket.s3.ap-southeast-3.amazonaws.com' + file} alt="" />
          </div>
        ) : null}
      </div>
    </div>
  );
};
