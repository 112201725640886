import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { useAppContext } from 'components/Context/AppContext';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDriverTasks } from 'features/drivers/actions';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
import { PaginationTable, Table, TableWrapper } from 'components/Global';
import EmptyState from 'components/Global/EmptyState';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import { ReactComponent as DriverIcon } from 'icons/driving-wheel-icon.svg';
import FilterTransaction from 'components/Global/TransactionFilter';
import useCreateTableData from 'utils/useCreateTableData';
import { resetSelected } from 'features/drivers/driverTaskSlice';
import { resetSelectedDriver } from 'features/drivers/slice';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Lengkap', value: 'name' },
  { header: 'Jenis Mobil', value: 'car' },
  { header: 'Mulai Sewa', value: 'start_date' },
  { header: 'Selesai Sewa', value: 'end_date' },
  { header: 'Jam Sewa', value: 'rent_time' },
  { header: 'Status Task', value: 'status' },
  { header: 'Fee Task', value: 'fee' },
  { header: 'Jenis Task', value: 'task_type' },
  { header: 'Driver ID', value: 'driver_id' },
];

const FILTER_DATA = [
  {
    id: 'OPEN',
    name: 'Belum Dapat Driver',
    value: false,
  },
  {
    id: 'BOOKED',
    name: 'Sudah Dapat Driver',
    value: false,
  },
  {
    id: 'RUNNING',
    name: 'Sedang Berjalan',
    value: false,
  },
  {
    id: 'FINISH',
    name: 'Selesai',
    value: false,
  },
  {
    id: 'CANCEL',
    name: 'Batal',
    value: false,
  },
];

const DriverTaskList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { setShowConfirmation, setShowCancelDriverTask, showCancelDriverTask } = useAppContext();

  const { data: driverTaskListData, isLoading: driverTaskListLoading } = useSelector((state) => state.driverTaskList);

  const [currentPage, setCurrentPage] = useState(1);
  const [newData, setNewData] = useState([]);
  const [filterStatus, setFilterStatus] = useState(FILTER_DATA);

  const [searchParams] = useSearchParams();
  const idTask = searchParams.get('id');
  const status = searchParams.get('status');

  const detailHandler = (e) => {
    const key = e.target.parentElement.parentElement.dataset.key;

    const customerId = driverTaskListData?.data?.find((item) => item?.order?.transaction_key == key)?.order
      ?.customer_id;
    const id = driverTaskListData?.data?.find((item) => item?.order?.transaction_key == key)?.id;

    dispatch(resetSelected());
    dispatch(resetSelectedDriver());

    if (customerId) {
      navigate(`/driver/driver-task?key=${key}&customer_id=${customerId}&id=${id}`);
    } else {
      navigate(`/driver/driver-task?key=${key}&id=${id}`);
    }
  };

  const cancelHandler = (e) => {
    const id = e.target.parentElement.parentElement.dataset.drivertaskid;

    setShowConfirmation({
      show: true,
      message: 'Apakah anda ingin membatalkan Task Order ini?',
      onClick: async () => {
        setShowCancelDriverTask(true);
        navigate(`/driver?tab-active=Driver+Task&status=${status}&id=${id}`);
      },
    });
  };

  useEffect(() => {
    localStorage.setItem('driver_task_page', currentPage);
    dispatch(
      getAllDriverTasks({
        page: currentPage,
        limit: 10,
        status: status === 'ALL' ? undefined : status?.split(','),
        orderBy: 'booking_start_date',
      }),
    );
  }, [currentPage]);

  useEffect(() => {
    if (
      !driverTaskListData ||
      checkEmptyObject(driverTaskListData) ||
      !driverTaskListData.data ||
      driverTaskListData.data.length === 0
    )
      return;

    const getCar = (orderType, item) => {
      if (!orderType || !item) return;

      if (orderType == '1' || orderType == '2') {
        return `${item.order?.order_detail?.vehicle?.brand_name} ${item.order?.order_detail?.vehicle?.name}`;
      } else if (orderType == '3' || orderType == '4') {
        return item.order.partner_vehicle?.name;
      } else if (orderType == '5' || orderType == '6') {
        return 'driver_car';
      }
    };

    const newDriverTaskData = driverTaskListData.data.map((item) => ({
      id: item.order?.transaction_key,
      user_name: item.order?.user_name,
      order_detail: {
        start_booking_date: item.order?.order_detail?.start_booking_date,
        end_booking_date: item.order?.order_detail?.end_booking_date,
      },
      car: getCar(item?.order?.order_type_id, item),
      rent_time: format(
        new Date(`${item.order?.order_detail?.start_booking_date} ${item.order?.order_detail?.start_booking_time}`),
        'hh:mm aa',
        {
          locale: id,
        },
      ),
      order_status: item.status === 'BOOKED' ? 'BOOKED-TASK' : item.status,
      fee: item.fee_per_task ? setCurrency(item.fee_per_task).replace('Rp', `${item.order.currency}`) : '-',
      driver_id: item.driver_id,
      task_id: item.id,
      is_driver_task: true,
      task_type:
        item.task_type === 'RETURN'
          ? 'Ambil Mobil'
          : item.task_type === 'DELIVERY'
          ? 'Antar Mobil'
          : item.task_type === 'TRAVEL'
          ? 'Full Service'
          : '-',
    }));

    setNewData(newDriverTaskData);
  }, [driverTaskListData]);

  useEffect(() => {
    if (!showCancelDriverTask) {
      localStorage.removeItem('fee-task');

      const urlParams = new URLSearchParams(location.search);
      urlParams.delete('id');

      navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true });
    }
  }, [showCancelDriverTask]);

  useEffect(() => {
    if (status?.toLowerCase() !== 'all') {
      setFilterStatus((prev) =>
        prev.map((item) =>
          status?.split(',').find((status) => status === item.id)
            ? { ...item, value: true }
            : { ...item, value: false },
        ),
      );
    } else if (status?.toLowerCase() == 'all') {
      setFilterStatus(FILTER_DATA);
    }
  }, [status]);

  useEffect(() => {
    if (status === 'ALL') {
      dispatch(getAllDriverTasks({ limit: 10, page: 1, orderBy: 'booking_start_date' }));
      setNewData([]);
    } else {
      dispatch(getAllDriverTasks({ limit: 10, page: 1, status: status.split(','), orderBy: 'booking_start_date' }));
      setNewData([]);
    }
  }, [status]);

  const { data } = useCreateTableData(newData);

  return (
    <TableWrapper
      icon={<DriverIcon fill="#009EF7" width="25px" height="25px" />}
      title="Driver Task"
      CustomFilterComponent={true}
      style={{ minHeight: '700px' }}
      CustomSortComponent={
        <FilterTransaction
          selected={filterStatus}
          setPage={setCurrentPage}
          className="filter-status"
          placeholder="Filter"
          params={status}
          paramName="status"
        />
      }
    >
      {driverTaskListLoading && !idTask ? (
        <LoadingSpinner />
      ) : newData.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <Table
            column={column}
            actionBtn
            onCancel={cancelHandler}
            // onEdit={editHandler}
            onDetail={detailHandler}
            data={data}
            currentPage={currentPage}
            scroll
          />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={driverTaskListData?.pagination?.total}
          />
        </>
      )}
    </TableWrapper>
  );
};

export default DriverTaskList;
