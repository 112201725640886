import React from 'react';
import { phoneWithCode, setCurrency } from 'utils/functionality';
import ActionButtons from './ActionButtons';
import StatusButton from './StatusButton';
import clsx from 'clsx';
import { ReactComponent as ChevronUpBlack } from 'icons/chevron-up-black.svg';
import { ReactComponent as ChevronDownBlack } from 'icons/chevron-down-black.svg';
import { ReactComponent as ChevronUpBlue } from 'icons/chevron-up-blue.svg';
import { ReactComponent as ChevronDownBlue } from 'icons/chevron-down-blue.svg';
import { ReactComponent as ChevronUpGray } from 'icons/chevron-up-gray.svg';
import { ReactComponent as ChevronDownGray } from 'icons/chevron-down-gray.svg';
import { useNavigate } from 'react-router-dom';

const Table = ({
  column = [], //table header
  onShow, // show or hide action button
  onEdit, // edit action button
  onDetail, // detail action button
  onDel, // delete action button
  onSendNotification,
  onSuccess,
  onDownloadInvoice,
  onUpdate,
  onCopy,
  onCancel,
  actionBtn,
  data = [], //table data
  maxRows = 10, //to populate table with empty row
  currentPage = 1,
  className,
  sort = false,
  columnToSort,
  sortBy = { count: 0, sortValue: '' },
  sortByHandler,
  scroll,
}) => {
  const navigate = useNavigate();
  const pageNumber = (page, idx) => (page > 1 ? (page - 1) * 10 + idx : idx);

  // const currentData = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * 10;
  //   const lastPageIndex = firstPageIndex + 10;
  //   return data.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage]);

  const rows = maxRows - data.length;

  return (
    <div className={clsx('table', className)} style={{ overflowY: scroll && 'hidden' }}>
      <table className="table-wrapper" style={{ width: scroll && `${(column.length + 5) * 10}%` }}>
        <thead className="table-head">
          <tr>
            {column.map((item, i) => (
              <th
                key={i}
                style={{ width: item.header === 'Jenis Mobil' || item.header === 'Jenis Package' ? '240px' : '' }}
              >
                {sort && item.header !== 'No.' ? (
                  <div className="sorted-wrapper" onClick={() => sortByHandler(item.value)}>
                    {item.header}
                    {columnToSort && columnToSort.length !== 0 && columnToSort.includes(item.header) && (
                      <div className="chevrons">
                        {sortBy.count === 0 ? (
                          <>
                            <ChevronUpBlack />
                            <ChevronDownBlack />
                          </>
                        ) : sortBy.count === 1 && sortBy.sortValue === item.value ? (
                          <>
                            <ChevronUpBlue />
                            <ChevronDownGray />
                          </>
                        ) : sortBy.count === 2 && sortBy.sortValue === item.value ? (
                          <>
                            <ChevronUpGray />
                            <ChevronDownBlue />
                          </>
                        ) : (
                          <>
                            <ChevronUpBlack />
                            <ChevronDownBlack />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <>{item.header}</>
                )}
              </th>
            ))}
            {actionBtn && <th className="action-col">Action</th>}
          </tr>
        </thead>
        <tbody className="table-body">
          {data.length > 0 &&
            data.map((item, no) => (
              <tr key={no}>
                {column.map((col, idx) => {
                  if (col.value === 'no') {
                    return (
                      <td
                        key={idx}
                        data-type="no"
                        className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                      >
                        {pageNumber(currentPage, no + 1)}
                      </td>
                    );
                  } else if (col.value === 'status') {
                    return (
                      <td
                        key={idx}
                        data-type={'status'}
                        className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                      >
                        {<StatusButton status={item[col.value]} />}
                      </td>
                    );
                  } else if (col.value === 'status_cancelation') {
                    return (
                      <td
                        key={idx}
                        data-type={'status'}
                        className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                      >
                        {<StatusButton status={item[col.value]} />}
                      </td>
                    );
                  } else if (
                    col.value === 'total_payment' ||
                    col.value === 'deposit' ||
                    col.value === 'total_commission'
                  ) {
                    return (
                      <td
                        key={idx}
                        data-type={col.value}
                        className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                      >
                        {setCurrency(item[col.value])}
                      </td>
                    );
                  } else if (col.value === 'additional') {
                    return (
                      <td
                        key={idx}
                        data-type="additional"
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {item.disablility_allowed ? 'For Disability' : 'No Disability'} <br />
                        {item.pet_allowed ? 'Pet Allowed' : 'No Pet Allowed'} <br />
                        {item.smoke_allowed ? 'Smoking Allowed' : 'No Smoking'}
                      </td>
                    );
                  } else if (col.value === 'license_number') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {item.license_number ? item.license_number : ''}
                      </td>
                    );
                  } else if (col.value === 'map_link') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                        style={{ textTransform: 'lowercase' }}
                      >
                        {item.map_link}
                      </td>
                    );
                  } else if (col.value === 'garage_time') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {item.start_time} - {item.end_time}
                      </td>
                    );
                  } else if (col.value === 'phone') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {phoneWithCode(item.phone_code, item.phone)}
                      </td>
                    );
                  } else if (col.value === 'wa_number') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {phoneWithCode(item.phone_code, item.wa_number)}
                      </td>
                    );
                  } else if (col.value === 'message') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {item[col.value].length > 10 ? item[col.value].slice(0, 10) + ' ...' : item[col.value]}
                      </td>
                    );
                  } else if (col.value === 'reward') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                        style={{ width: '80%' }}
                      >
                        {setCurrency(item.reward).replace('Rp', 'IDR')}
                      </td>
                    );
                  } else if (col.value === 'promo_price') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {item.value_type === 'percentage' ? `${item.promo_price}%` : setCurrency(item.promo_price)}
                      </td>
                    );
                  } else if (col.value === 'promo_vehicles') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        <div className="promo-vehicles">
                          {!item.promo_vehicles || item.promo_vehicles.length === 0 ? (
                            <>-</>
                          ) : (
                            item.promo_vehicles.map((li, i) => (
                              <div key={Math.random() + i} className="promo-vehicles__list">
                                <span>{li.name}</span>
                                {li.total > 1 && <span>{li.total}</span>}
                              </div>
                            ))
                          )}
                        </div>
                      </td>
                    );
                  } else if (col.value === 'address_details') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space address_details ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {item[col.value].length > 25 ? item[col.value].slice(0, 25) + ' ...' : item[col.value]}
                      </td>
                    );
                  } else if (col.value === 'schedule_price_vehicles') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        <div className="schedule-price-vehicles">
                          {!item.schedule_price_vehicles || item.schedule_price_vehicles.length === 0 ? (
                            <>-</>
                          ) : (
                            item.schedule_price_vehicles.map((li, i) => (
                              <div key={Math.random() + i} className="schedule-price-vehicles__list">
                                <span>{li.name}</span>
                                {li.total > 1 && <span>{li.total}</span>}
                              </div>
                            ))
                          )}
                        </div>
                      </td>
                    );
                  } else if (col.value === 'min_rent_vehicles') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        <div className="schedule-price-vehicles">
                          {!item.min_rent_vehicles || item.min_rent_vehicles.length === 0 ? (
                            <>-</>
                          ) : (
                            item.min_rent_vehicles.map((li, i) => (
                              <div key={Math.random() + i} className="schedule-price-vehicles__list">
                                <span>{li.name}</span>
                                {li.total > 1 && <span>{li.total}</span>}
                              </div>
                            ))
                          )}
                        </div>
                      </td>
                    );
                  } else if (col.value === 'schedule_price_locations') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                        style={{ width: '240px' }}
                      >
                        <div className="schedule-price-locations">
                          {!item[col.value] || item[col.value].length === 0 ? (
                            <>-</>
                          ) : (
                            item[col.value].map((li, i) => (
                              <div key={Math.random() + i}>
                                <span>{li}</span>
                              </div>
                            ))
                          )}
                        </div>
                      </td>
                    );
                  } else if (col.value === 'business_rule_location') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                        style={{ width: '240px' }}
                      >
                        <div className="schedule-price-locations">
                          {!item[col.value] || item[col.value].length === 0 ? (
                            <>-</>
                          ) : (
                            item[col.value].map((li, i) => (
                              <div key={Math.random() + i}>
                                <span>{li.name}</span>
                              </div>
                            ))
                          )}
                        </div>
                      </td>
                    );
                  } else if (col.value === 'schedule-price-reward') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                        style={{ width: '895px' }}
                      >
                        {setCurrency(item.reward).replace('Rp', 'IDR')}
                      </td>
                    );
                  } else if (col.value === 'type') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space address_details ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {<StatusButton status={item[col.value]?.toLowerCase()} />}
                      </td>
                    );
                  } else if (col.value === 'name' && col.header === 'Nama Tipe Daerah') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space address_details ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                        style={{ width: '850px' }}
                      >
                        <p style={{ textTransform: 'capitalize' }}>{item[col.value]}</p>
                      </td>
                    );
                  } else if (col.value === 'location') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space address_details ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                        style={{ width: '389px' }}
                      >
                        {item[col.value]}
                      </td>
                    );
                  } else if (col.value === 'time_zone') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space address_details ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {item[col.value] ? item[col.value] : '-'}
                      </td>
                    );
                  } else if (col.value === 'sub_services') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space address_details ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {!item[col.value] || !item[col.value].length
                          ? '-'
                          : item[col.value].map((item) => item.name).join(', ')}
                      </td>
                    );
                  } else if (col.value === 'description') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space address_details ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                        dangerouslySetInnerHTML={{ __html: item[col.value] }}
                      ></td>
                    );
                  } else if (col.value === 'color_variation') {
                    return (
                      <td
                        key={idx}
                        data-key={item[col.value] ? JSON.stringify(item[col.value]) : 0}
                        className={`pre-white-space address_details ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {item[col.value]?.length || '-'}
                      </td>
                    );
                  } else if (col.value === 'unit_price') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space address_details ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {setCurrency(item[col.value]) || '-'}
                      </td>
                    );
                  } else if (col.value === 'amount') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space address_details ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        {setCurrency(item[col.value]) || '-'}
                      </td>
                    );
                  } else if (col.value === 'order_key') {
                    return (
                      <td
                        key={idx}
                        style={{ color: '#0084FF' }}
                        className={`pre-white-space address_details ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        <span
                          className="order-key-refund"
                          onClick={() => {
                            if (item?.order_type_id === 5 || item?.order_type_id === 6) {
                              return navigate(
                                `/one-way/detail/${item?.order_transaction_key}?customerId=${item?.customer_id}`,
                              );
                            } else if (item?.order_type_id === 1) {
                              if (item?.without_driver) {
                                return navigate(
                                  `/without-driver/detail/${item?.order_transaction_key}?customerId=${item?.customer_id}`,
                                );
                              } else {
                                return navigate(
                                  `/with-driver/detail/${item?.order_transaction_key}?customerId=${item?.customer_id}`,
                                );
                              }
                            } else {
                              return navigate(
                                `/airport-transfer/detail/${item?.order_transaction_key}?customerId=${item?.customer_id}`,
                              );
                            }
                          }}
                        >
                          {item[col.value] || '-'}
                        </span>
                      </td>
                    );
                  } else if (col.value === 'rental_duration_vehicles') {
                    return (
                      <td
                        key={idx}
                        className={`pre-white-space address_details ${
                          col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''
                        }`}
                      >
                        <div className="rental-duration-vehicles">
                          {!item.rental_duration_vehicles || item.rental_duration_vehicles.length === 0 ? (
                            <>-</>
                          ) : (
                            item.rental_duration_vehicles.map((li) => (
                              <div key={li.id} className="rental-duration-vehicles__list">
                                <span>{li.name}</span>
                                {li.total > 1 && <span>{li.total}</span>}
                              </div>
                            ))
                          )}
                        </div>
                      </td>
                    );
                  } else {
                    return (
                      <td
                        key={idx}
                        data-type={col.value}
                        className={col?.highlight_color ? `col-highlight-${col?.highlight_color}` : ''}
                      >
                        {item[col.value]}
                      </td>
                    );
                  }
                })}
                {actionBtn && (
                  <td
                    className="btn-action"
                    data-type="actionButtons"
                    data-key={!item.id ? item.code : item.id}
                    data-drivertaskid={item['is_driver_task'] ? item.task_id : null}
                  >
                    <ActionButtons
                      show={onShow}
                      del={onDel}
                      detail={onDetail}
                      edit={
                        item['is_driver_task'] && item.status === 'open'
                          ? onEdit
                          : item['is_driver_task'] && item.status !== 'open'
                          ? undefined
                          : onEdit
                      }
                      sendNotification={onSendNotification}
                      success={onSuccess}
                      downloadInvoice={onDownloadInvoice}
                      trxUpdate={onUpdate}
                      copy={onCopy}
                      cancel={
                        item['is_driver_task'] && (item.status === 'open' || item.status === 'booked-task')
                          ? onCancel
                          : item['is_driver_task'] && (item.status !== 'open' || item.status !== 'booked-task')
                          ? undefined
                          : onCancel
                      }
                    />
                  </td>
                )}
              </tr>
            ))}
          {maxRows !== 0 &&
            rows > 0 &&
            [...Array(rows)].map((_, idx) => (
              <tr key={idx}>
                {column.map((_, idx) => (
                  <td key={idx}></td>
                ))}
                {actionBtn && <td className="btn-action"></td>}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
