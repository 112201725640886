import { createSlice } from '@reduxjs/toolkit';
import { fetchOneWayOrder } from './actions';

const initialState = {
  data: {},
  page: 1,
  status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const oneWayOrder = createSlice({
  name: 'oneWayOrder',
  initialState,
  reducers: {
    saveVehicleData: (state, action) => {
      return { ...state, vehicleList: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOneWayOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOneWayOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchOneWayOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      });
  },
});

export const { saveVehicleData } = oneWayOrder.actions;

export default oneWayOrder.reducer;
