import { createSlice } from '@reduxjs/toolkit';
import {
  getAllRentalDuration,
  getDetailRentalDuration,
  createRentalDuration,
  updateRentalDuration,
  deleteRentalDuration,
} from './actions';

const initialState = {
  data: {},
  selected: {},
  isLoading: false,
  isError: false,
  errorMessage: {},
};

export const rentalDuration = createSlice({
  name: 'rentalDuration',
  initialState,
  reducers: {
    // reducers goes here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRentalDuration.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
        state.data = {};
      })
      .addCase(getAllRentalDuration.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getAllRentalDuration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getDetailRentalDuration.pending, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = {};
        state.selected = {};
      })
      .addCase(getDetailRentalDuration.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(getDetailRentalDuration.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selected = action.payload;
      })
      .addCase(createRentalDuration.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(createRentalDuration.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(createRentalDuration.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateRentalDuration.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(updateRentalDuration.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(updateRentalDuration.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteRentalDuration.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = {};
      })
      .addCase(deleteRentalDuration.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteRentalDuration.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export default rentalDuration.reducer;
