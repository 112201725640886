import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
import { Button, InputField } from 'components/Global';
import TextAreaInput from 'components/Global/TextArea';
import { fetchOrderById } from 'features/orders/actions';
import { getDetailOrder } from 'features/orders/detailOrderSlice';

const RefundDetailForm = ({ setShowImage, setSelectedImage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setShowConfirmation, setShowRefundProcess, setShowRefundTransferModal, setShowRejectRefundModal } =
    useAppContext();

  const { selected } = useSelector((state) => state.refund);
  const orderData = useSelector(getDetailOrder);

  const backHandler = () => {
    navigate(-1);
  };

  const imagePreviewHandler = (image) => {
    setShowImage(true);
    setSelectedImage(image);
  };

  const processRefundHandler = () => {
    setShowConfirmation({
      show: true,
      message: 'Apakah anda yakin ingin melanjutkan proses refund?',
      onClick: () => setShowRefundProcess(true),
    });
  };

  const rejectRefundHandler = () => {
    setShowConfirmation({
      show: true,
      message: 'Apakah anda yakin ingin menolak proses refund?',
      onClick: () => setShowRejectRefundModal(true),
    });
  };

  const confirmRefundHandler = async () => {
    setShowRefundTransferModal(true);
  };

  useEffect(() => {
    if (!selected || checkEmptyObject(selected)) return;

    dispatch(fetchOrderById(selected.order_transaction_key));
  }, [selected]);

  return (
    <>
      <div className="form">
        <InputField label="Nama Lengkap" disable readOnly value={selected?.customer_name || '-'} />
        <InputField
          label="Kode Order"
          readOnly
          value={selected?.order_key || '-'}
          className="order-key"
          onClick={() => {
            if (selected?.order_type_id === 5 || selected?.order_type_id === 6) {
              return navigate(`/one-way/detail/${selected?.order_transaction_key}?customerId=${selected?.customer_id}`);
            } else if (selected?.order_type_id === 1) {
              if (selected?.without_driver) {
                return navigate(
                  `/without-driver/detail/${selected?.order_transaction_key}?customerId=${selected?.customer_id}`,
                );
              } else {
                return navigate(
                  `/with-driver/detail/${selected?.order_transaction_key}?customerId=${selected?.customer_id}`,
                );
              }
            } else {
              return navigate(
                `/airport-transfer/detail/${selected?.order_transaction_key}?customerId=${selected?.customer_id}`,
              );
            }
          }}
        />
        <InputField label="Nama Bank Customer" disable readOnly value={selected?.customer_bank_name || '-'} />
        <InputField label="No  Rekening Customer" disable readOnly value={selected?.customer_bank_number || '-'} />
        <InputField label="Metode Pembayaran" disable readOnly value={selected?.payment_method || '-'} />
        <InputField
          label="Nama Bank Pembayaran"
          disable
          readOnly
          value={orderData?.disbursement?.payment?.code || '-'}
        />
        <InputField
          label="Jumlah Bayar"
          disable
          readOnly
          value={setCurrency(selected?.refund_amount)?.replace('Rp', selected?.currency) || '-'}
        />
        <div className="image-wrapper">
          <InputField
            label="Bukti Transfer"
            disable
            readOnly
            value={
              selected?.payment_method == 'Manual Transfer'
                ? orderData?.disbursement?.disbursement_confirmation_image?.split('/')[5]
                : 'Tidak ada Bukti Transfer'
            }
          />
          {selected?.payment_method == 'Manual Transfer' && (
            <Button
              className="button-preview"
              height="30px"
              onClick={() => imagePreviewHandler(`${orderData?.disbursement?.disbursement_confirmation_image}`)}
            >
              Lihat
            </Button>
          )}
        </div>
        <TextAreaInput
          label="Alasan Pengembalian Dana"
          disable
          value={orderData?.order_cancelation?.cancelation_reason}
          className="reason-canclation"
        />
      </div>
      <div className="action-btn">
        <Button variant="outline" onClick={backHandler}>
          Kembali
        </Button>
        {selected?.status !== 'TRANSFERED' && selected?.status !== 'REJECTED' && (
          <Button className="reject-btn" onClick={rejectRefundHandler}>
            Tolak Permintaan
          </Button>
        )}
        {selected?.status === 'CREATED' && (
          <Button onClick={processRefundHandler} className="process-btn">
            Proses Refund
          </Button>
        )}
        {selected?.status === 'PROCESSED' && <Button onClick={confirmRefundHandler}>Konfirmasi</Button>}
      </div>
    </>
  );
};

export default RefundDetailForm;
