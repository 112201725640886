import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  approvalWithdraw,
  getDetailDriver,
  getDetailWithdraw,
  getTransactionHistory,
  getVehiclePackages,
} from 'features/drivers/actions';
import { checkEmptyObject, setCurrency } from 'utils/functionality';
import { useAppContext } from 'components/Context/AppContext';
import { Button, InputField, PaginationTable, TableWrapper } from 'components/Global';
import InputTelpField from 'components/Global/InputTelpField';
import PreviewImage from 'components/Modals/PreviewImage';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import { ReactComponent as DriverIcon } from 'icons/driving-wheel-icon.svg';
import { ReactComponent as Calendar } from 'icons/calendar-icon.svg';
import { ReactComponent as DownloadIcon } from 'icons/download-image-blue-icon.svg';
import { ReactComponent as MappinIcon } from 'icons/mappin-icon.svg';
import { ReactComponent as ChevronDown } from 'icons/arrow-down-icon.svg';
import { format } from 'date-fns';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const DetailWithdraw = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    setShowWithdrawFeeModal,
    setShowSpinner,
    setShowConfirmation,
    setShowTransferWithdrawFee,
    setShowRejectWithdrawFee,
    showToast,
  } = useAppContext();

  const {
    selected: selectedWithdrawData,
    transactionHistory: transactionHistoryData,
    isLoading: withDrawLoading,
  } = useSelector((state) => state.withdraw);
  const {
    selected: selectedDriversData,
    isLoading: driversLoading,
    vehiclePackages,
  } = useSelector((state) => state.drivers);

  const [showImage, setShowImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const backHandler = () => {
    navigate(-1);
  };

  const imagePreviewHandler = (image) => {
    setShowImage(true);
    setSelectedImage(image);
  };

  const withdrawFeeHandler = () => {
    setShowWithdrawFeeModal(true);
  };

  const rejectWithdrawFeeHandler = () => {
    setShowConfirmation({
      show: true,
      message: 'Apakah anda ingin menolak permintaan Withdraw ini?',
      onClick: async () => {
        setShowRejectWithdrawFee(true);
      },
    });
  };

  const confirmationWithdrawFeeHandler = () => {
    setShowConfirmation({
      show: true,
      message: 'Apakah anda ingin konfirmasi penyelesaian Transfer Manual penarikan dana?',
      onClick: async () => {
        try {
          await dispatch(approvalWithdraw({ id, status: 'approve' })).unwrap();
          showToast({ type: 'success', message: 'Berhasil Proses Withdraw' });
          dispatch(getDetailWithdraw(id));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          showToast({ type: 'error', message: 'Terjadi Kesalahan' });
        }
      },
    });
  };

  const downloadTransferProof = (image) => {
    const imageUrl = `${IMAGE_BASE_URL}${image}`;
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'downloaded-image.jpg';
    link.click();
    link.remove();
  };

  useEffect(() => {
    dispatch(getDetailWithdraw(id));
  }, []);

  useEffect(() => {
    if (!selectedWithdrawData || checkEmptyObject(selectedWithdrawData)) return;
    dispatch(getDetailDriver(selectedWithdrawData.withdraw?.request_by_id));
    dispatch(
      getTransactionHistory({
        id: selectedWithdrawData.withdraw?.request_by_id,
        showType: 'wd_admin',
        refId: id,
        limit: 10,
        page: currentPage,
      }),
    );
  }, [selectedWithdrawData, currentPage]);

  useEffect(() => {
    if (!selectedDriversData || checkEmptyObject(selectedDriversData)) return;

    const locationId = selectedDriversData?.user_location?.location_id;
    dispatch(getVehiclePackages(locationId));
  }, [selectedDriversData]);

  useEffect(() => {
    if (withDrawLoading || driversLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [withDrawLoading, driversLoading]);

  return (
    <>
      <div className="withdraw">
        <header className="withdraw__header" onClick={backHandler}>
          <LeftArrow />
          <p>Kembali</p>
        </header>

        <TableWrapper icon={<DriverIcon fill="#009EF7" width="25px" height="25px" />} title="Driver">
          <h2>Detail Driver</h2>
          <div className="withdraw__driver-detail">
            <InputField label="Nama" disable readOnly value={selectedDriversData?.name || '-'} />

            <InputField label="Email" disable readOnly value={selectedDriversData?.email || '-'} />

            <InputTelpField
              label="No Handphone"
              countryCodeValue={selectedDriversData?.phone_code || '+62'}
              disable
              readOnly
              value={selectedDriversData?.phone || '-'}
            />

            <InputField
              label="Tanggal Lahir"
              disable
              readOnly
              value={selectedDriversData?.PersonalInfos?.date_of_birth || 'Pilih Tanggal'}
              icon={<Calendar />}
              iconPosition="start"
            />

            <InputField
              label="Domisili Driver"
              disable
              readOnly
              value={selectedDriversData?.PersonalInfos?.domicile || '-'}
            />

            <InputField
              label="Lokasi Driver"
              disable
              readOnly
              value={selectedDriversData?.user_location?.location_name || '-'}
              icon={<MappinIcon />}
              iconPosition="start"
            />

            <div className="image-wrapper">
              <InputField
                label="KTP"
                disable
                readOnly
                value={
                  selectedDriversData?.PersonalInfos?.ktp ? selectedDriversData?.PersonalInfos?.ktp : 'Belum Upload KTP'
                }
              />
              {selectedDriversData?.PersonalInfos?.ktp && (
                <Button
                  className="button-preview"
                  height="30px"
                  onClick={() => imagePreviewHandler(`${IMAGE_BASE_URL}${selectedDriversData?.PersonalInfos?.ktp}`)}
                >
                  Lihat
                </Button>
              )}
            </div>

            <div className="image-wrapper">
              <InputField
                label="SIM"
                disable
                readOnly
                value={
                  selectedDriversData?.PersonalInfos?.sim ? selectedDriversData?.PersonalInfos?.sim : 'Belum Upload SIM'
                }
              />
              {selectedDriversData?.PersonalInfos?.sim && (
                <Button
                  className="button-preview"
                  height="30px"
                  onClick={() => imagePreviewHandler(`${IMAGE_BASE_URL}${selectedDriversData?.PersonalInfos?.sim}`)}
                >
                  Lihat
                </Button>
              )}
            </div>

            <div className="image-wrapper">
              <InputField
                label="Foto Selfie"
                disable
                readOnly
                value={
                  selectedDriversData?.PersonalInfos?.selfie
                    ? selectedDriversData?.PersonalInfos?.selfie
                    : 'Belum Upload Foto Selfie'
                }
              />
              {selectedDriversData?.PersonalInfos?.selfie && (
                <Button
                  className="button-preview"
                  height="30px"
                  onClick={() => imagePreviewHandler(`${IMAGE_BASE_URL}${selectedDriversData?.PersonalInfos?.selfie}`)}
                >
                  Lihat
                </Button>
              )}
            </div>

            <div className="image-wrapper">
              <InputField
                label="Surat Perjanjian"
                disable
                readOnly
                value={
                  selectedDriversData?.PersonalInfos?.letter_of_statement
                    ? selectedDriversData?.PersonalInfos?.letter_of_statement
                    : 'Belum Upload Surat Perjanjian'
                }
              />
              {selectedDriversData?.PersonalInfos?.letter_of_statement && (
                <Button
                  className="button-preview"
                  height="30px"
                  onClick={() =>
                    imagePreviewHandler(`${IMAGE_BASE_URL}${selectedDriversData?.PersonalInfos?.letter_of_statement}`)
                  }
                >
                  Lihat
                </Button>
              )}
            </div>

            <InputField
              label="Nomor Rekening"
              disable
              readOnly
              value={selectedDriversData?.account_bank?.no_rek || '-'}
            />

            <InputField
              label="Nama Bank"
              disable
              readOnly
              value={selectedDriversData?.account_bank?.nama_bank || '-'}
              icon={<ChevronDown />}
              iconPosition="end"
            />

            <InputField label="Nama Mobil" disable readOnly value={selectedDriversData?.vehicle?.name || '-'} />

            <InputField
              label="Nomor Polisi Mobil"
              disable
              readOnly
              value={selectedDriversData?.vehicle?.license_number || '-'}
            />

            <InputField
              label="Tipe Paket Driver"
              disable
              readOnly
              value={
                vehiclePackages?.find((item) => item.id === selectedDriversData?.vehicle?.vehicle_package_id)?.name ||
                '-'
              }
              icon={<ChevronDown />}
              iconPosition="end"
            />

            <InputField label="Driver ID" disable readOnly value={selectedDriversData?.id || '-'} />
          </div>
        </TableWrapper>

        <div className="withdraw__saldo-wrapper">
          <h2>Detail Saldo</h2>
          <div className="withdraw__saldo-detail">
            <p>Jumlah Saldo</p>
            <p>
              {selectedWithdrawData?.withdraw?.status !== 'COMPLETED' &&
              selectedWithdrawData?.withdraw?.status !== 'REJECTED'
                ? setCurrency(selectedWithdrawData?.available_amount + selectedWithdrawData?.withdraw?.amount)?.replace(
                    'Rp',
                    selectedWithdrawData?.currency === null ? '' : selectedWithdrawData?.currency,
                  )
                : selectedWithdrawData?.withdraw?.status === 'REJECTED'
                ? setCurrency(selectedWithdrawData?.available_amount)?.replace(
                    'Rp',
                    selectedWithdrawData?.currency === null ? '' : selectedWithdrawData?.currency,
                  )
                : setCurrency(selectedWithdrawData?.available_amount)?.replace(
                    'Rp',
                    selectedWithdrawData?.currency === null ? '' : selectedWithdrawData?.currency,
                  )}
            </p>
          </div>

          <h2>Detail Penarikan</h2>
          <div className="withdraw__fee-detail">
            <p>Jumlah Permintaan Nominal Withdraw</p>
            <p>
              {setCurrency(selectedWithdrawData?.withdraw?.amount)?.replace(
                'Rp',
                selectedWithdrawData?.currency === null ? '' : selectedWithdrawData?.currency,
              )}
            </p>

            <div className="action-button">
              {selectedWithdrawData?.withdraw?.status !== 'COMPLETED' &&
                selectedWithdrawData?.withdraw?.status !== 'REJECTED' && (
                  <Button className="button-withdraw cancel" onClick={rejectWithdrawFeeHandler}>
                    Tolak Permintaan
                  </Button>
                )}

              {selectedWithdrawData?.withdraw?.status === 'REQUEST' && (
                <Button className="button-withdraw" onClick={withdrawFeeHandler}>
                  Proses Withdraw
                </Button>
              )}

              {selectedWithdrawData?.withdraw?.status === 'PROCESSED' && (
                <Button className="button-withdraw confirmation" onClick={confirmationWithdrawFeeHandler}>
                  Konfirmasi Sudah Diproses
                </Button>
              )}
            </div>

            <div
              className="transfer-proof-download"
              onClick={
                selectedWithdrawData?.withdraw?.status !== 'REQUEST' &&
                !selectedWithdrawData?.withdraw?.proof_of_transfer
                  ? () => setShowTransferWithdrawFee(true)
                  : () => downloadTransferProof(selectedWithdrawData?.withdraw?.proof_of_transfer)
              }
            >
              {selectedWithdrawData?.withdraw?.status !== 'REQUEST' &&
              selectedWithdrawData?.withdraw?.status !== 'REJECTED' &&
              selectedWithdrawData?.withdraw?.status !== 'COMPLETED' &&
              !selectedWithdrawData?.withdraw?.proof_of_transfer ? (
                <p>Upload Bukti Transfer</p>
              ) : selectedWithdrawData?.withdraw?.status !== 'REQUEST' &&
                selectedWithdrawData?.withdraw?.status !== 'REJECTED' &&
                selectedWithdrawData?.withdraw?.proof_of_transfer ? (
                <>
                  <DownloadIcon />
                  <p>Download Bukti Transfer</p>
                </>
              ) : null}
            </div>
          </div>

          <h2>History Transaksi</h2>
          <ul className="withdraw__transaction">
            {checkEmptyObject(transactionHistoryData) ||
            !transactionHistoryData.data ||
            transactionHistoryData.data.length === 0 ? (
              <p className="empty-data">Belum ada Transaksi</p>
            ) : (
              transactionHistoryData.data.map((item) => (
                <li key={item.id}>
                  <div>
                    <p>{item.description}</p>
                    <p>
                      {format(new Date(item.date), 'dd MMMM yyyy')} {format(new Date(item.date), 'HH:mm:ss')}
                    </p>
                  </div>
                  <p className={clsx('amount', item.amount < 0 ? 'minus' : 'plus')}>
                    {item.amount < 0
                      ? `- ${setCurrency(item.amount)
                          .replace('-', '')
                          .replace('Rp', item.currency === null ? '' : item.currency)}`
                      : `+ ${setCurrency(item.amount)
                          .replace('+', '')
                          .replace('Rp', item.currency === null ? '' : item.currency)}`}
                  </p>
                </li>
              ))
            )}
          </ul>
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={transactionHistoryData?.pagination?.total}
          />
        </div>
      </div>
      {showImage &&
        ReactDOM.createPortal(
          <PreviewImage image={selectedImage} setShow={() => setShowImage(false)} />,
          document.getElementById('modal'),
        )}
    </>
  );
};

export default DetailWithdraw;
