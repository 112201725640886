import React from 'react';

const StatusButton = ({ status }) => {
  const getStatus = () => {
    if (status === 'active' || status == 'approved' || status == 'new') {
      return {
        text: 'Active',
        color: 'active',
      };
    }

    if (status === 'inactive') {
      return {
        text: 'Inactive',
        color: 'not-active',
      };
    }

    if (status === 'blocked') {
      return {
        text: 'Blocked',
        color: 'not-active',
      };
    }

    if (status === 'redeem') {
      return {
        text: 'Redeem',
        color: 'status-indebt',
      };
    }

    if (status === 'success') {
      return {
        text: 'Success',
        color: 'active',
      };
    }

    if (status === 'pending') {
      return {
        text: 'Pending',
        color: 'warning',
      };
    }

    if (status === 'not-active') {
      return {
        text: 'Terblokir',
        color: 'not-active',
      };
    }

    if (status === 'available') {
      return {
        text: 'Ready',
        color: 'active',
      };
    }

    if (status === 'booked') {
      return {
        text: 'In Use',
        color: 'warning',
      };
    }

    if (status === 'locked') {
      return {
        text: 'In Repair',
        color: 'not-active',
      };
    }

    if (status === 'requested') {
      return {
        text: 'Requested',
        color: 'warning',
      };
    }

    if (status === 'paid') {
      return {
        text: 'Belum Diproses',
        color: 'status-paid',
      };
    }

    if (status === 'completed') {
      return {
        text: 'Sedang Berjalan',
        color: 'status-running',
      };
    }

    if (status === 'finished') {
      return {
        text: 'Selesai Sewa',
        color: 'status-completed',
      };
    }

    if (status === 'checkout') {
      return {
        text: 'Konfirmasi Order',
        color: 'status-checkout',
      };
    }

    if (status === 'cancelled') {
      return {
        text: 'Batal Sewa',
        color: 'status-cancelled',
      };
    }

    if (status === 'failed') {
      return {
        text: 'Failed',
        color: 'status-cancelled',
      };
    }

    if (status === 'reconfirmation') {
      return {
        text: 'Re-Konfirmasi',
        color: 'status-reconfirmation',
      };
    }

    if (status === 'full') {
      return {
        text: 'Lunas',
        color: 'status-full',
      };
    }

    if (status === 'half') {
      return {
        text: 'DP',
        color: 'status-half',
      };
    }

    if (status === 'rejected') {
      return {
        text: 'Rejected',
        color: 'status-cancelled',
      };
    }

    if (status === 'created') {
      return {
        text: 'Baru',
        color: 'status-completed',
      };
    }

    if (status === 'transfered') {
      return {
        text: 'Selesai',
        color: 'status-finished',
      };
    }

    if (status === 'on_debt') {
      return {
        text: 'Hutang',
        color: 'status-indebt',
      };
    }

    if (status === 'processed') {
      return {
        text: 'Diproses',
        color: 'status-processed',
      };
    }

    if (status === 'published') {
      return {
        text: 'Publish',
        color: 'status-published',
      };
    }

    if (status === 'drafted') {
      return {
        text: 'Draft',
        color: 'status-drafted',
      };
    }

    if (status === 'waiting_approval') {
      return {
        text: 'Waiting Approval',
        color: 'status-approval',
      };
    }

    if (status === 'reviewing_identity') {
      return {
        text: 'Review Identity',
        color: 'status-approval',
      };
    }

    /* ---- STATUS TASK ADMIN ---- */
    if (status === 'baru') {
      return {
        text: 'Baru',
        color: 'status-completed',
      };
    }

    if (status === 'ditolak') {
      return {
        text: 'Ditolak',
        color: 'status-cancelled',
      };
    }

    if (status === 'selesai') {
      return {
        text: 'Selesai',
        color: 'status-finished',
      };
    }

    /* ----- STATUS DRIVER TASKS ----- */
    if (status === 'open') {
      return {
        text: 'Belum Dapat Driver',
        color: 'status-open',
      };
    }

    if (status === 'searching_for_driver') {
      return {
        text: 'Sedang Mencari Driver',
        color: 'status-open',
      };
    }

    if (status === 'booked-task' || status === 'booked') {
      return {
        text: 'Sudah Dapat Driver',
        color: 'status-booked',
      };
    }

    if (status === 'running') {
      return {
        text: 'Sedang Berjalan',
        color: 'status-running',
      };
    }

    if (status === 'on_pick_up_location') {
      return {
        text: 'Menjemput Penumpang',
        color: 'status-running',
      };
    }

    if (status === 'transporting_passenger') {
      return {
        text: 'Mengantar Penumpang',
        color: 'status-running',
      };
    }

    if (status === 'finish') {
      return {
        text: 'Selesai',
        color: 'status-completed',
      };
    }

    if (status === 'cancel') {
      return {
        text: 'Batal',
        color: 'status-cancelled',
      };
    }

    if (status === 'on_pick_up_location') {
      return {
        text: 'Menjemput Penumpang',
        color: 'status-running',
      };
    }

    if (status === 'not_proceed') {
      return {
        text: 'Belum Dipublish',
        color: 'status-open',
      };
    }

    /* ------------------- */

    if (typeof status == 'boolean') {
      if (status) {
        return {
          text: 'Applied',
          color: 'status-completed',
        };
      }
      return {
        text: 'Not Applied',
        color: 'status-processed',
      };
    }

    /* ----- WITHDRAW -----*/
    if (status?.toLowerCase() === 'completed') {
      return {
        text: 'Berhasil',
        color: 'status-completed',
      };
    }

    if (status?.toLowerCase() === 'request') {
      return {
        text: 'Belum Diproses',
        color: 'status-request',
      };
    }

    if (status?.toLowerCase() === 'processed') {
      return {
        text: 'Diproses',
        color: 'status-approval',
      };
    }

    if (status?.toLowerCase() === 'rejected') {
      return {
        text: 'Ditolak',
        color: 'status-cancelled',
      };
    }

    /* ---- Refund ---- */
    if (status === 'refund-transfered') {
      return {
        text: 'Selesai',
        color: 'status-completed',
      };
    }

    if (status === 'refund-rejected') {
      return {
        text: 'Batal',
        color: 'status-cancelled',
      };
    }

    if (status === 'refund-processed') {
      return {
        text: 'Diproses',
        color: 'status-indebt',
      };
    }

    if (status === 'refund-created') {
      return {
        text: 'Belum Diproses',
        color: 'status-processed',
      };
    }

    return {
      text: status,
      color: 'status-default',
    };
  };

  return (
    <div className={`btn-status ${getStatus().color}`}>
      <p>{getStatus().text}</p>
    </div>
  );
};

export default StatusButton;
