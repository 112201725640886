import React, { useEffect, useState } from 'react';
import Modals from '../Modals';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'components/Context/AppContext';
import { Button, SelectFieldVehicle } from 'components/Global';
import { getAllRentalLocation } from 'features/rental-location/actions';
import { ReactComponent as RentDurationIcon } from 'icons/durasi-sewa-filled-icon.svg';
import SelectFieldDropdown from 'components/Global/SelectFieldDropdown';
import { getServices } from 'features/services/actions';
import { getVehiclesByFilter } from 'features/vehicle/action';
import {
  createRentalDuration,
  getAllRentalDuration,
  getDetailRentalDuration,
  updateRentalDuration,
} from 'features/rental-duration/actions';
import { useSearchParams } from 'react-router-dom';
import { checkEmptyObject } from 'utils/functionality';
import SelectFieldTime from 'components/Global/SelectFieldTime';

const AddRentalDurationModal = () => {
  const dispatch = useDispatch();

  const { setShowAddRentalDurationModal, showToast } = useAppContext();

  const { data: rentalLocationData } = useSelector((state) => state.rentalLocation);
  const { data: servicesData } = useSelector((state) => state.services);
  const { data: vehiclesData } = useSelector((state) => state.vehicle);
  const { selected: selectedRentalDuration } = useSelector((state) => state.rentalDuration);

  const [searchParams, setSearchParams] = useSearchParams();
  const paramsId = searchParams.get('id');

  const [serviceTypeData, setServiceTypeData] = useState([]);
  const [selectedCar, setSelectedCar] = useState([]);

  const [rentalLocation, setRentalLocation] = useState(0);
  const [selectedServiceType, setSelectedServiceType] = useState(0);
  const [durationRental, setDurationRental] = useState('');
  const [selectedVehicles, setSelectedVehicles] = useState([]);

  const intersectionAction = () => {
    // if last page then just return nothing
    if (vehiclesData.pagination.page >= vehiclesData.pagination.last_page) return;

    // if not last page fetch
    const withoutDriverService =
      serviceTypeData?.find((item) => item.name == selectedServiceType)?.name === 'Without Driver';

    if (withoutDriverService) {
      dispatch(getVehiclesByFilter({ supportDriver: false, page: 1, locationIds: [+rentalLocation] }));
    } else {
      dispatch(getVehiclesByFilter({ supportDriver: true, page: 1, locationIds: [+rentalLocation] }));
    }
  };

  const requestAction = async (method, payload, successMessage, failedMessage) => {
    try {
      if (method === 'ADD') {
        await dispatch(createRentalDuration(payload)).unwrap();
      } else {
        await dispatch(updateRentalDuration({ id: paramsId, ...payload })).unwrap();
      }
      showToast({ type: 'success', message: successMessage });
      dispatch(getAllRentalDuration({ page: 1, limit: 10 }));
      backHandler(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      showToast({ type: 'error', message: failedMessage });
    }
  };

  const handleSelectedCar = (cars) => {
    let choosedVehiclesId = [];
    for (let choosedData of cars) {
      for (let chooseCar of choosedData.cars) {
        choosedVehiclesId.push(chooseCar.id);
      }
    }

    setSelectedVehicles(choosedVehiclesId);
    setSelectedCar(cars);
  };

  const backHandler = (status) => {
    setShowAddRentalDurationModal(status);

    if (paramsId) {
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  };

  const submitHandler = async () => {
    const validRentalLocation = rentalLocation !== 0;
    const validSelectedServiceType = selectedServiceType !== 0;
    const validSelectedVehicles = selectedVehicles.length !== 0;
    const validDurationRental = durationRental !== '';

    if (!validRentalLocation) {
      return showToast({ type: 'error', message: 'Plih Lokasi' });
    } else if (!validSelectedServiceType) {
      return showToast({ type: 'error', message: 'Plih Tipe Sewa' });
    } else if (!validSelectedVehicles) {
      return showToast({ type: 'error', message: 'Plih Mobil' });
    } else if (!validDurationRental) {
      return showToast({ type: 'error', message: 'Masukan Durasi Sewa' });
    }

    const getServiceName = (serviceName) => {
      return serviceName.toLowerCase().replace(/\s+/g, '_');
    };

    const payload = {
      duration: +durationRental,
      service: getServiceName(serviceTypeData?.find((item) => item.id == selectedServiceType)?.name),
      location_id: +rentalLocation,
      vehicle_ids: selectedVehicles,
    };

    if (paramsId) {
      await requestAction('UPDATE', payload, 'Berhasil Ubah Durasi Sewa', 'Gagal Ubah Durasi Sewa');
    } else {
      await requestAction('ADD', payload, 'Berhasil Tambah Durasi Sewa', 'Gagal Tamabah Durasi Sewa');
    }
  };

  useEffect(() => {
    dispatch(getAllRentalLocation());
    dispatch(getServices());

    if (paramsId) {
      dispatch(getDetailRentalDuration(paramsId));
    }
  }, [paramsId]);

  useEffect(() => {
    if (!servicesData || servicesData.length === 0) return;

    const sewaMobilService = servicesData.find((item) => item.name === 'Sewa Mobil');
    let mappingServiceData = [];

    for (let child of sewaMobilService.sub_services) {
      if (child.id === 1) {
        child.facilities?.forEach((item) => {
          if (item.name === 'With Driver') {
            mappingServiceData.push({ id: item.id, name: item.name });
          }
        });
      }
    }

    setServiceTypeData(mappingServiceData);
    setSelectedServiceType(
      sewaMobilService?.sub_services
        ?.find((item) => item.name === 'Daily')
        ?.facilities?.find((item) => item.name === 'With Driver')?.id,
    );
  }, [servicesData]);

  useEffect(() => {
    if (!rentalLocation || !selectedServiceType) return;

    const withoutDriverService =
      serviceTypeData?.find((item) => item.name == selectedServiceType)?.name === 'Without Driver';

    if (withoutDriverService) {
      dispatch(getVehiclesByFilter({ supportDriver: false, page: 1, locationIds: [+rentalLocation] }));
    } else {
      dispatch(getVehiclesByFilter({ supportDriver: true, page: 1, locationIds: [+rentalLocation] }));
    }
  }, [rentalLocation, selectedServiceType]);

  useEffect(() => {
    if (!selectedRentalDuration || checkEmptyObject(selectedRentalDuration)) return;

    const selectedService = serviceTypeData.find(
      (ser) =>
        ser.name ===
        selectedRentalDuration.service
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '),
    )?.id;

    setRentalLocation(selectedRentalDuration.location_id);
    setSelectedServiceType(selectedService);
    setSelectedVehicles(selectedRentalDuration.vehicle_ids);
    setDurationRental(selectedRentalDuration.duration);
  }, [selectedRentalDuration]);

  useEffect(() => {
    if (
      !paramsId ||
      !vehiclesData ||
      checkEmptyObject(vehiclesData) ||
      !selectedRentalDuration ||
      checkEmptyObject(selectedRentalDuration)
    )
      return;

    let newSelectedVehicle = [];

    for (let child of vehiclesData.vehicles) {
      for (let childSelectedVehicle of selectedRentalDuration.vehicle_ids) {
        if (child.id === childSelectedVehicle) {
          if (newSelectedVehicle.length === 0) {
            newSelectedVehicle.push({ cars: [child], total: 1 });
          } else {
            const existingVehicle = newSelectedVehicle.find((veh) => veh.cars[0]?.name === child.name);
            if (existingVehicle) {
              newSelectedVehicle = newSelectedVehicle.map((veh) => {
                if (veh.cars[0]?.name === child.name) {
                  return {
                    cars: [...veh.cars, child],
                    total: veh.total + 1,
                  };
                }

                return {
                  ...veh,
                };
              });
            } else {
              newSelectedVehicle.push({ cars: [child], total: 1 });
            }
          }
        }
      }
    }

    setSelectedCar(newSelectedVehicle);
  }, [vehiclesData, selectedRentalDuration]);

  return (
    <Modals
      setState={backHandler}
      title={paramsId ? 'Ubah Durasi Sewa' : 'Tambah Durasi Sewa'}
      icon={<RentDurationIcon width="25px" height="25px" />}
    >
      <div className="rental-duration-modal">
        <form className="rental-duration-modal__form">
          <div className="field">
            <SelectFieldDropdown
              label="Lokasi"
              htmlFor="location"
              placeholder="Pilih Lokasi"
              data={rentalLocationData}
              onChange={(e) => setRentalLocation(e.target.id)}
              value={rentalLocation}
            />
            <SelectFieldDropdown
              label="Tipe Sewa"
              htmlFor="order-type"
              placeholder="Pilih Tipe Sewa"
              data={serviceTypeData}
              // onClick={(e) => setSelectedServiceType(e.target.id)}
              value={selectedServiceType}
              disable
            />
            <SelectFieldVehicle
              label="Jenis Mobil"
              htmlFor="jenis-mobil"
              placeholder="Cari Mobil.."
              data={vehiclesData}
              selectedCar={selectedCar}
              onSelectCar={handleSelectedCar}
              intersectionAction={intersectionAction}
              disable={!rentalLocation || !selectedServiceType}
            />
            <SelectFieldTime
              label="Durasi Sewa (jam)"
              htmlFor="jam-mulai"
              placeholder="Pilih Durasi Sewa"
              value={durationRental}
              onChange={(hour) => {
                setDurationRental(+hour);
              }}
              showFullHoursDay={true}
              hideMinutes
            />
          </div>
          <div className="action-btn">
            <Button type="button" variant="outline" width={251} height={50} onClick={() => backHandler(false)}>
              Kembali
            </Button>
            <Button type="button" width={251} height={50} onClick={submitHandler}>
              {paramsId ? 'Update' : 'Tambah'}
            </Button>
          </div>
        </form>
      </div>
    </Modals>
  );
};

export default AddRentalDurationModal;
