import React from 'react';
import SearchBar from './SearchBar';
import Button from '../Button';

const TableWrapper = ({
  title,
  icon,
  filter,
  CustomFilterComponent,
  links,
  children,
  search,
  setSearch,
  searchPlaceholder,
  CustomSortComponent,
  style,
  showExportButton,
  onExportToPdf,
  importFromExcel,
  exportToExcel,
}) => {
  return (
    <div className="g-table" style={style}>
      <header className="table-header">
        <div className="table-title">
          {icon}
          <h1>{title}</h1>
        </div>
        <div className="table-filter">
          {importFromExcel && importFromExcel}
          {exportToExcel && exportToExcel}
          {CustomFilterComponent && CustomFilterComponent}
          {CustomSortComponent && CustomSortComponent}
          {filter && (
            <SearchBar
              type="text"
              value={search}
              placeholder={searchPlaceholder}
              onChange={setSearch ? setSearch : () => {}}
            />
          )}
          {showExportButton && (
            <div className="btn-export">
              <Button
                variant="primary"
                style={{
                  background: '#F1A33A',
                }}
                onClick={onExportToPdf}
              >
                <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3.33337 13.3333H12.6667V12H3.33337V13.3333ZM12.6667 6H10V2H6.00004V6H3.33337L8.00004 10.6667L12.6667 6Z"
                      fill="white"
                    />
                  </svg>
                </span>
                Export to PDF
              </Button>
            </div>
          )}
          {links && <p className="link">Lihat Semua</p>}
        </div>
      </header>
      {children}
    </div>
  );
};

export default TableWrapper;
