import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { ReactComponent as Clock } from '../../../icons/clock-icon.svg';
import ClickOutside from '../ClickOutside';

const SelectFieldTime = ({
  label,
  name,
  htmlFor,
  value, // state
  className,
  style,
  placeholder,
  onChange,
  disable,
  // choosenDate,
  icon = true,
  startHour,
  showAllHours,
  variantHours,
  hideHours,
  hideMinutes,
  showFullHoursDay,
  ...rest
}) => {
  // TODAY'S TIME
  // const currentHour = new Date().getHours();
  // const today = new Date().getDate();
  // const thisMonth = new Date().getMonth();
  // const thisYear = new Date().getFullYear();

  // SHOW DROPDOWN FUNCTION
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  // STATE HOUR & MINUTE
  const [hour, setHour] = useState('07');
  const [minute, setMinute] = useState('00');

  useEffect(() => {
    if (!value) return;

    if (hideHours) {
      setMinute(value);
    } else if (hideMinutes) {
      setHour(value);
    } else {
      setHour(value?.split(':')[0]);
      setMinute(value?.split(':')[1]);
    }
  }, [value]);

  // DIFFERENCE DAYS STATE
  // const [differenceDays, setDifferenceDays] = useState(null);

  // SET THE TIME
  const setTimeBooking = (hour, minute) => {
    onChange(hour, minute);
    setIsShowDropdown(false);
  };

  // SET DIFFERENCE DAYS WHEN CHOOSEN DATE CHANGING
  // useEffect(() => {
  //   const difference =
  //     choosenDate && choosenDate.getMonth() === thisMonth && choosenDate.getFullYear() === thisYear
  //       ? choosenDate.getDate() - today
  //       : 1;
  //   setDifferenceDays(difference);
  // }, [choosenDate]);

  const listHours =
    variantHours && variantHours === 'WITHOUT_DRIVER'
      ? startHour && Array.from({ length: +startHour.split(':')[0] + 1 }, (_, i) => i)
      : showAllHours
      ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
      : showFullHoursDay
      ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
      : [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];

  const timeLength = listHours
    .slice(listHours.indexOf(parseInt(Number(startHour?.split(':')[0]), 10)) + 1)
    .concat(listHours.slice(0, listHours.indexOf(parseInt(Number(startHour?.split(':')[0]), 10)) + 1));

  return (
    <div className={clsx('select-field-time', className)} style={{ width: '100%' }}>
      {label && (
        <label htmlFor={htmlFor} className="select-field-time__label">
          {label}
        </label>
      )}
      <div
        className={disable ? 'select-field-time__input-field disable' : 'select-field-time__input-field'}
        style={{ width: '100%' }}
      >
        {icon && <Clock className="select-field-time__input-field__calendar" />}
        <input
          id={htmlFor}
          type="text"
          placeholder={placeholder}
          name={name}
          style={style}
          className="select-field-time__input-field__input"
          value={value}
          disabled={disable}
          onFocus={() => setIsShowDropdown(true)}
          readOnly
          {...rest}
        />
      </div>
      {isShowDropdown && (
        <ClickOutside onClickOutside={() => setIsShowDropdown(false)}>
          <div className="select-field-time__dropdown" style={{ width: '246px' }}>
            <div
              className="select-field-time__dropdown__container"
              style={{ gridTemplateColumns: `repeat(${hideHours || hideMinutes ? 1 : 2}, minmax(100px, 1fr))` }}
            >
              {hideHours ? null : (
                <div className="select-field-time__dropdown__container__jam">
                  <h4>Jam</h4>
                  {showAllHours ? (
                    <ul style={{ height: '100%' }}>
                      {listHours.map((item, idx) => (
                        <li
                          key={idx}
                          className={item == hour ? 'list-hour active' : 'list-hour'}
                          onClick={() => {
                            item < 10 ? setHour(`0${item}`) : setHour(item);
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  ) : variantHours ? (
                    <ul style={{ height: '100%' }}>
                      {(showAllHours && startHour ? timeLength : listHours).map((item, idx) => (
                        <li
                          key={idx}
                          className={item == hour ? 'list-hour active' : 'list-hour'}
                          onClick={() => {
                            item < 10 ? setHour(`0${item}`) : setHour(item);
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  ) : showFullHoursDay ? (
                    <ul style={{ height: '100%' }}>
                      {listHours.map((item, idx) => (
                        <li
                          key={idx}
                          className={item == hour ? 'list-hour active' : 'list-hour'}
                          onClick={() => {
                            item < 10 ? setHour(`0${item}`) : setHour(item);
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <ul>
                      {Array.from({ length: 15 }, (_, i) => i + 7).map((item, idx) => (
                        <li
                          key={idx}
                          className={item == hour ? 'list-hour active' : 'list-hour'}
                          onClick={() => {
                            item < 10 ? setHour(`0${item}`) : setHour(item);
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
              {hideMinutes ? null : (
                <div className="select-field-time__dropdown__container__menit">
                  <h4>Menit</h4>
                  <ul>
                    {[0, 30].map((item, idx) => (
                      <li
                        key={idx}
                        className={item == minute ? 'list-minute active' : 'list-minute'}
                        onClick={() => {
                          item == 0 ? setMinute(`0${item}`) : setMinute(item);
                        }}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="select-field-time__dropdown__container__selesai">
              <h3 onClick={() => setTimeBooking(hour, minute)}>Selesai</h3>
            </div>
          </div>
        </ClickOutside>
      )}
    </div>
  );
};

export default SelectFieldTime;
