import React, { useEffect, useState } from 'react';
import { Button, PaginationTable, Table, TableWrapper } from 'components/Global';
import { ReactComponent as PlusIcon } from 'icons/plus-icon.svg';
import { ReactComponent as RentDurationIcon } from 'icons/durasi-sewa-filled-icon.svg';
import LoadingSpinner from 'components/Global/LoadingSpinner';
import EmptyState from 'components/Global/EmptyState';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRentalDuration, getAllRentalDuration } from 'features/rental-duration/actions';
import { useAppContext } from 'components/Context/AppContext';
import { checkEmptyObject } from 'utils/functionality';
import { useSearchParams } from 'react-router-dom';

const column = [
  { header: 'No.', value: 'no' },
  { header: 'Lokasi', value: 'location_name' },
  { header: 'Tipe Sewa', value: 'service' },
  { header: 'Mobil', value: 'rental_duration_vehicles' },
  { header: 'Durasi Sewa', value: 'duration' },
];

const DurasiSewaComponent = () => {
  const dispatch = useDispatch();

  const { setShowAddRentalDurationModal, showToast, setShowConfirmation } = useAppContext();

  const { data: rentalDurationData, isLoading: rentalDurationLoading } = useSelector((state) => state.rentalDuration);

  const [searchParams, setSearchParams] = useSearchParams();
  const paramsId = searchParams.get('id');

  const [newRentalDurationData, setNewRentalDurationData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const addRentalDurationHandler = () => {
    setShowAddRentalDurationModal(true);
  };

  const editRentalDuration = (e) => {
    const rentalDurationId = e.target.parentElement.parentElement.dataset.key;
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), id: rentalDurationId });
  };

  const deleteRentalDurationHandler = async (e) => {
    const rentalDurationId = e.target.parentElement.parentElement.dataset.key;

    setShowConfirmation({
      show: true,
      message: 'Apakah anda yakin ingin menghapus?',
      variant: 'danger',
      valueButton: 'Hapus',
      onClick: async () => {
        try {
          await dispatch(deleteRentalDuration(rentalDurationId)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Hapus Data' });
          dispatch(getAllRentalDuration({ page: currentPage, limit: 10 }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          showToast({ type: 'error', message: 'Gagal Hapus Data' });
        }
      },
    });
  };

  useEffect(() => {
    dispatch(getAllRentalDuration({ page: currentPage, limit: 10 }));
  }, [currentPage]);

  useEffect(() => {
    if (!rentalDurationData || checkEmptyObject(rentalDurationData) || !rentalDurationData.rental_duration_settings)
      return;

    const getRentalDurationVehicles = (vehicles) => {
      let newData = [];

      for (let child of vehicles) {
        if (newData.length === 0) {
          newData.push({ id: child.id, name: child.name, total: 1 });
        } else {
          const existingVehicle = newData.find((data) => data.name === child.name);
          if (existingVehicle) {
            newData = newData.map((data) => {
              if (data.name === existingVehicle.name) {
                return {
                  ...data,
                  total: data.total + 1,
                };
              }

              return {
                ...data,
              };
            });
          } else {
            newData.push({ id: child.id, name: child.name, total: 1 });
          }
        }
      }

      return newData;
    };

    const newData = rentalDurationData.rental_duration_settings.map((item) => ({
      ...item,
      duration: item.duration + ' Jam',
      service: item.service
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
      rental_duration_vehicles: getRentalDurationVehicles(item.vehicles),
    }));

    setNewRentalDurationData(newData);
  }, [rentalDurationData]);

  useEffect(() => {
    if (paramsId) {
      setShowAddRentalDurationModal(true);
    }
  }, [paramsId]);

  return (
    <>
      <Button className="add-btn" width={192} height={39} onClick={addRentalDurationHandler}>
        <PlusIcon />
        Tambah Durasi Sewa
      </Button>

      <TableWrapper
        icon={<RentDurationIcon fill="#009EF7" width="25px" height="25px" />}
        title="Durasi Sewa"
        style={{ minHeight: '700px' }}
      >
        {rentalDurationLoading ? (
          <LoadingSpinner />
        ) : !rentalDurationData?.rental_duration_settings ||
          rentalDurationData?.rental_duration_settings?.length === 0 ? (
          <EmptyState />
        ) : (
          <>
            <Table
              column={column}
              data={newRentalDurationData}
              currentPage={currentPage}
              actionBtn
              onEdit={editRentalDuration}
              onDel={deleteRentalDurationHandler}
            />
            <PaginationTable
              currentPage={currentPage}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              totalCount={rentalDurationData?.pagination?.total}
            />
          </>
        )}
      </TableWrapper>
    </>
  );
};

export default DurasiSewaComponent;
