import clsx from 'clsx';
import { ReactComponent as ChevronDown } from 'icons/chevron-down.svg';

const SelectField = ({
  label,
  name,
  htmlFor,
  value, // state
  onChange, // setState
  data = [],
  className,
  style,
  placeholder,
  disable,
  ...rest
}) => {
  return (
    <div className={clsx('g-input', className)} style={style}>
      <label htmlFor={htmlFor} className="input-title">
        {label}
      </label>
      <section className="field-wrapper select">
        <select
          id={htmlFor}
          name={name}
          onChange={onChange}
          className={disable ? 'input-field disable' : 'input-field'}
          style={{ paddingRight: '40px' }}
          value={value}
          {...rest}
        >
          {!value && <option value="null">{placeholder}</option>}
          {data.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        <ChevronDown className="icon" />
      </section>
    </div>
  );
};

export default SelectField;
