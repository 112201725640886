import { useState, useEffect } from 'react';
import { Table, TableWrapper, PaginationTable } from 'components/Global';
import { ReactComponent as LocationFilledIcon } from 'icons/location-filled-icon.svg';
import { useAppContext } from 'components/Context/AppContext';
import { useSelector } from 'react-redux';
// import PaginationTable from 'components/Global/Pagination';
import { useDispatch } from 'react-redux';
import { deleteRentalLocation, getAllRentalLocation, getRentalLocationById } from 'features/rental-location/actions';
import EmptyState from 'components/Global/EmptyState';
import { getServices } from 'features/services/actions';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ButtonAdd from 'components/Global/ButtonAdd';
import { getAllCurrencies } from 'features/banks/actions';

// Table column header
const column = [
  { header: 'No.', value: 'no' },
  { header: 'Nama Daerah Sewa', value: 'name' },
  { header: 'Time Zone Identifier', value: 'time_zone_identifier' },
  { header: 'Time Zone', value: 'time_zone' },
  { header: 'Layanan', value: 'sub_services' },
];

const CityComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // context
  const { setShowAddNewCity, setShowSpinner, showToast, setShowConfirmation } = useAppContext();

  // global state rental location
  const { data: rentalData, isError, errorMessage } = useSelector((state) => state.rentalLocation);

  const [searchParams] = useSearchParams();

  // state of rental location data
  const [rentalLocations, setRentalLocations] = useState([]);

  // page state
  const [currentPage, setCurrentPage] = useState(1);
  const maxRowsInOnePage = 10;
  // const pageCount = Math.ceil(data.length / 10);

  const [isLoading, setIsLoading] = useState(false);

  // handle add city
  const handleNewCity = () => {
    setShowAddNewCity(true);
    dispatch(getServices());
  };

  // handle delete city
  const handleDelete = (e) => {
    const cityNameId = e.target.parentElement.parentElement.dataset['key'];

    setShowConfirmation({
      message: 'Apakah anda yakin ingin menghapus Item',
      show: true,
      onClick: async () => {
        try {
          setShowSpinner(true);
          await dispatch(deleteRentalLocation(cityNameId)).unwrap();
          showToast({ type: 'success', message: 'Berhasil Hapus Item' });
          setCurrentPage(1);
        } catch (error) {
          showToast({ type: 'error', message: 'Gagal Hapus Item' });
        } finally {
          setShowSpinner(false);
        }
      },
    });
  };

  // handle edit city
  const handleEdit = (e) => {
    const cityNameId = e.target.parentElement.parentElement.dataset['key'];
    dispatch(getServices());
    dispatch(getRentalLocationById(cityNameId));
    navigate(`/rental-area?location_id=${cityNameId}`);
    setShowAddNewCity(true);
  };

  // call all rental location
  useEffect(() => {
    dispatch(getAllRentalLocation());
    dispatch(getAllCurrencies());

    if (searchParams.get('location_id')) {
      dispatch(getServices());
      dispatch(getRentalLocationById(searchParams.get('location_id')));
      navigate(`/rental-area?location_id=${searchParams.get('location_id')}`);
      setShowAddNewCity(true);
    }
  }, []);

  // mapping rental locations with subservices
  useEffect(() => {
    if (!rentalData || !rentalData.length) return;

    setIsLoading(true);

    const getAllRentalData = async () => {
      const promise = rentalData.map((item) => dispatch(getRentalLocationById(item.id)).unwrap());
      const results = await Promise.allSettled(promise);
      const fulfilledResults = results.filter((res) => res.status === 'fulfilled')?.map((item) => item.value);

      setRentalLocations(
        fulfilledResults.map((item) => ({
          ...item,
          sub_services: item.sub_services?.filter(
            (item) =>
              item.name === 'With Driver' ||
              item.name === 'Without Driver' ||
              item.name === 'Airport Transfer' ||
              item.name === 'One Way',
          ),
        })),
      );
      setIsLoading(false);
    };

    getAllRentalData();
  }, [rentalData]);

  // loading state
  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [isLoading]);

  // will show the error message when there is an error
  useEffect(() => {
    if (!isError) return;

    try {
      if (Object?.prototype?.hasOwnProperty?.call(errorMessage, 'slug')) {
        showToast({ type: 'error', message: errorMessage.slug });
      } else {
        showToast({ type: 'error', message: 'Terjadi Kesalahan' });
      }
    } catch (error) {
      showToast({ type: 'error', message: 'Terjadi Kesalahan' });
    }
  }, [isError, errorMessage]);

  return (
    <div className="city">
      <ButtonAdd onClick={handleNewCity} label="Tambah Daerah Sewa" />

      {!rentalLocations || !rentalLocations.length ? (
        <EmptyState />
      ) : (
        <TableWrapper title="Daerah Sewa" icon={<LocationFilledIcon fill="#FF9900" width="25px" height="25px" />}>
          <Table
            column={column}
            data={rentalLocations?.slice(
              currentPage === 1 ? 0 : currentPage * maxRowsInOnePage - maxRowsInOnePage,
              currentPage * maxRowsInOnePage,
            )}
            actionBtn
            onDel={handleDelete}
            currentPage={currentPage}
            onEdit={handleEdit}
          />
          <PaginationTable
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
            totalCount={rentalLocations.length}
          />
        </TableWrapper>
      )}
    </div>
  );
};

export default CityComponent;
