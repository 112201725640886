import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as ChevronDown } from 'icons/chevron-down.svg';
import { ReactComponent as Checklist } from 'icons/check-filled-green-icon.svg';
import { ReactComponent as RejectIcon } from 'icons/reject-filled-red-icon.svg';
import { format, parseISO } from 'date-fns';
import { id } from 'date-fns/locale';
// import { getRefundHistories } from 'features/refund/actions';
// import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Button } from 'components/Global';
import { setCurrency } from 'utils/functionality';

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGES;

const RefundHistory = ({ setShowImage, setSelectedImage }) => {
  // const dispatch = useDispatch();
  // const location = useLocation();

  // const idRefund = location.pathname.split('/')[2];

  const { selected } = useSelector((state) => state.refund);

  const [openAccordion, setOpenAccordion] = useState(false);

  const getStatusRefundDate = (his) => {
    if (!his) return;

    const statusDate = format(parseISO(his.created_at), 'EEEE dd MMMM', {
      locale: id,
    });
    const statusHour = format(parseISO(his.created_at), 'HH:mm:ss', {
      locale: id,
    });

    return `${statusDate} ${statusHour}`;
  };

  const getRefundStatusStyle = (status) => {
    if (status === 'CREATED') {
      return 'refund-created';
    } else if (status === 'PROCESSED') {
      return 'refund-processed';
    } else if (status === 'TRANSFERED') {
      return 'refund-transfered';
    } else if (status === 'REJECTED') {
      return 'refund-rejected';
    }
  };

  const imagePreviewHandler = (image) => {
    setShowImage(true);
    setSelectedImage(image);
  };

  // useEffect(() => {
  //   dispatch(getRefundHistories({ id: idRefund, page: 1, limit: 10 }));
  // }, []);

  return (
    <div className="refund-history">
      <h2 className="title">History Refund</h2>

      <div className="histories">
        <div className="histories__container">
          <div className="history-data">
            <div className="history-data__header">
              <h6>Permintaan Pengajuan Refund Dana</h6>
              <p>{selected?.order_key}</p>
            </div>
            <div className="history-data__data">
              <div className="column">
                <p>Nama</p>
                <p className="text-xs font-bold">{selected?.customer_bank_account_name || '-'}</p>
              </div>
              <div className="column">
                <p>Status Refund Dana</p>
                <div className={clsx('status-refund', getRefundStatusStyle(selected?.status))}>
                  {selected?.status.charAt(0).toUpperCase() + selected?.status.slice(1).toLowerCase() || '-'}
                </div>
              </div>
              <div className="column">
                <p>Nama Bank</p>
                <p className="text-xs font-bold">{selected?.customer_bank_name || '-'}</p>
              </div>
              <div className="column">
                <p>Nomor Rekening</p>
                <p className="text-xs font-bold">{selected?.customer_bank_number || '-'}</p>
              </div>
              <div className="column">
                <p>Dana Yang telah di refund</p>
                <p className="text-xs font-bold">
                  {setCurrency(selected?.refund_amount).replace('Rp', selected?.currency) || '-'}
                </p>
              </div>
            </div>

            {selected?.proof_of_transfer_refund && (
              <div className="history-data__transfer">
                <label>Bukti Transfer</label>
                <input type="text" readOnly disabled value={selected?.proof_of_transfer_refund?.split('/')[2]} />
                <Button
                  className="button-preview"
                  onClick={() => imagePreviewHandler(`${IMAGE_BASE_URL}${selected?.proof_of_transfer_refund}`)}
                >
                  Lihat
                </Button>
              </div>
            )}
          </div>

          <div className="history-status-progress">
            <header
              className="history-status-progress__header"
              onClick={() => setOpenAccordion((prevState) => !prevState)}
            >
              <h6>Status Refund Dana</h6>
              <ChevronDown />
            </header>

            <div
              className="history-status-progress__progress"
              style={{
                transition: '0.3s ease-in-out',
                height: openAccordion ? '165px' : '0px',
                opacity: openAccordion ? '1' : '0',
              }}
            >
              <div className="container">
                <div className="progress">
                  <div className="circle-gray" />
                  <div className="status-check">
                    <p className="status">Created</p>
                    {selected?.refund_order_histories?.find((his) => his.status === 'CREATED') && (
                      <p className="date">
                        {getStatusRefundDate(selected?.refund_order_histories?.find((his) => his.status === 'CREATED'))}
                      </p>
                    )}
                  </div>
                  <Checklist className="check-icon" />
                </div>

                <div className="progress">
                  <div
                    className={clsx(
                      'bar',
                      (selected?.refund_order_histories?.find((his) => his.status === 'PROCESSED') &&
                        selected?.refund_order_histories?.length === 3) ||
                        (selected?.refund_order_histories?.find((his) => his.status === 'PROCESSED') &&
                          selected?.refund_order_histories?.length === 2)
                        ? 'active'
                        : selected?.refund_order_histories?.length === 2 &&
                          selected?.refund_order_histories?.find((his) => his.status === 'REJECTED')
                        ? 'reject'
                        : 'non-active',
                    )}
                  />
                  <div className="circle-gray" />
                  <div className="status-check">
                    <p className="status">Processed</p>
                    {selected?.refund_order_histories?.find((his) => his.status === 'PROCESSED') && (
                      <p className="date">
                        {getStatusRefundDate(
                          selected?.refund_order_histories?.find((his) => his.status === 'PROCESSED'),
                        )}
                      </p>
                    )}
                  </div>
                  {(selected?.refund_order_histories?.find((his) => his.status === 'PROCESSED') &&
                    selected?.refund_order_histories?.length === 3) ||
                  (selected?.refund_order_histories?.find((his) => his.status === 'PROCESSED') &&
                    selected?.refund_order_histories?.length === 2) ? (
                    <Checklist className="check-icon" />
                  ) : selected?.refund_order_histories?.length === 2 &&
                    selected?.refund_order_histories?.find((his) => his.status === 'REJECTED') ? (
                    <RejectIcon className="check-icon" />
                  ) : null}
                </div>

                <div className="progress">
                  <div
                    className={clsx(
                      'bar',
                      selected?.refund_order_histories?.find(
                        (his) => his.status === 'TRANSFERED' || his.status === 'REJECTED',
                      )?.status === 'TRANSFERED'
                        ? 'active'
                        : selected?.refund_order_histories?.find(
                            (his) => his.status === 'TRANSFERED' || his.status === 'REJECTED',
                          )?.status === 'REJECTED'
                        ? 'reject'
                        : 'non-active',
                    )}
                  />
                  <div className="circle-gray" />
                  <div className="status-check">
                    <p className="status">
                      {selected?.refund_order_histories?.find(
                        (his) => his.status === 'TRANSFERED' || his.status === 'REJECTED',
                      )
                        ? `${selected?.refund_order_histories
                            ?.find((his) => his.status === 'TRANSFERED' || his.status === 'REJECTED')
                            ?.status.charAt(0)}${selected?.refund_order_histories
                            ?.find((his) => his.status === 'TRANSFERED' || his.status === 'REJECTED')
                            ?.status.slice(1)
                            .toLowerCase()}`
                        : 'Transfered'}
                    </p>
                    {selected?.refund_order_histories?.find(
                      (his) => his.status === 'TRANSFERED' || his.status === 'REJECTED',
                    ) && (
                      <p className="date">
                        {getStatusRefundDate(
                          selected?.refund_order_histories?.find(
                            (his) => his.status === 'TRANSFERED' || his.status === 'REJECTED',
                          ),
                        )}
                      </p>
                    )}
                  </div>
                  {selected?.refund_order_histories?.find(
                    (his) => his.status === 'TRANSFERED' || his.status === 'REJECTED',
                  )?.status === 'TRANSFERED' ? (
                    <Checklist className="check-icon" />
                  ) : selected?.refund_order_histories?.find(
                      (his) => his.status === 'TRANSFERED' || his.status === 'REJECTED',
                    )?.status === 'REJECTED' ? (
                    <RejectIcon className="check-icon" />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundHistory;
